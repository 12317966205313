/*
 * Container
 */
.main-container {
  margin-top: 60px;
}

.container-content {
  overflow: hidden;
}

#footer {
  height: 174px;

  a {
    color: #fff;
  }

  .footer-inner {
    position: absolute;
    width: 100%;
    bottom: 0px;
    background: #4A4A4A;
    padding-top: 35px;
  }

  .nav-pills {
    display: inline-block;

    a:hover {
      background: none;
      color: rgb(145, 145, 145);
    }
    .active a {
      background: none;
    }
  }
}

#aequifin .footer-copyright {
  margin: 28px 0 20px;
  font-size: 11px;
  @media (max-width:961px)  {
    padding-bottom: 2em;
  }

  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.alternate-background {
  background-color: rgb(243, 244, 245);
}

.no-left-padding {
  padding-left: 0;
}