/* DEFAULT STYLE */
:root {
  --card-img-height: 200px;
}

.news-logo {
  display: inline-block;
  margin-top: -5px;
}

.news-slogan {
  display: inline-block;
}

.card-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease-in-out;
  flex-wrap: wrap;
}

.card {
  align-self: stretch;
  position: relative;
  width: 270px;
  min-width: 270px;
  margin: 1.25rem 0.75rem;
  background: white;
  transition: all 300ms ease-in-out;
  //box-shadow: 10px 10px 5px grey;

  .card-img {
    visibility: hidden;
    width: 100%;
    height: var(--card-img-height);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .card-img-hovered {
    --card-img-hovered-overlay: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
    transition: all 350ms ease-in-out;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: absolute;
    height: var(--card-img-height);
    top: 0;
  }

  .card-info {
    position: relative;
    padding: 0.75rem 1.25rem;
    transition: all 200ms ease-in-out;

    > hr {
      border-top: 1px solid black;
      width: 100%;
    }

    .card-about {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 0;
      transition: all 200ms ease-in-out;

      .card-tag {
        width: 60px;
        max-width: 100px;
        padding: 0.2rem 0.5rem;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        background: #19427b;
        color: #fff;

        &.tag-1 {
          background: #19427b;
        }
        &.tag-2 {
          background: #00b1ff;
        }

        &.tag-3 {
          background: #6e267a;
        }

        &.tag-4 {
          background: #f07f09;
        }
      }
    }

    .card-title {
      z-index: 10;
      font-size: 1.5rem;
      padding-bottom: 0.75rem;
      transition: all 350ms ease-in-out;
      display: flex;
      align-items: center;
      height: 30px;
    }

    .card-description {
      padding-bottom: 0.75rem;
      transition: all 250ms ease-in-out;
    }
  }

  &:hover {
    cursor: pointer;
    //box-shadow: 0px 15px 35px rgba(227, 252, 239, 0.1),
    //0px 5px 15px rgba(0, 0, 0, 0.07);
    transform: scale(1.025);

    .card-img-hovered {
      --card-img-hovered-overlay: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.65)
      );
      height: 100%;
    }

    .card-about,
    .card-description {
      opacity: 0;
    }

    .card-info {
      background-color: transparent;
    }

    .card-info > hr {
      border-top: 0 solid black;
      width: 100%;
    }

    .card-title {
      color: #ebecf0;
      // margin-bottom: -40px;
      transform: translate(0, 40px);
    }
  }
}

@media only screen and (min-width: 769px) {
  .mobile-news-container {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .card-container {
    display: none;
  }
}

.mobile-news-container > .row {
  cursor: pointer;
}

.mobile-news-image {
  border-radius: 50%;
  margin: 2.5px;
  border: 0.1px solid #337ab7;
}

.mobile-news-text {
  display: flex;
  align-items: center;
  height: 85px;
}

.mobile-news-container > hr {
  border-top: 1px solid black;
  width: 80%;
}

.mobile-news-title {
  padding: 0;
}

.currentNews-action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}
