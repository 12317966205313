.login-teaser-top {
  height: 80vh;
}

@media screen and (max-width: 813px) and (orientation: landscape) and (max-height: 400px){
  .login-teaser-top {
    height: 160vh;
  }
}

.teaser-top {
  position: relative;

  .fixed-row {
    height: 70vh;
    position: relative;
    margin-left: auto;
    margin-right: auto;

    > .col-sm-12 {
      position: absolute;
      top: 40%;
      transform: translateY(-50%);
    }
  }

  hr {
    clear: both;
    margin: 10px 0 30px;
    height: 0;
    border-top-color: #f0f0f0;
  }

  .fixed-row {
    vertical-align: middle;
  }

  h1 {
    text-shadow: 1px 1px rgb(50, 50, 50);
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
  }
}

.teaser-text-container {
  height: 100%;
  padding: 40px 25px 10px 25px;
}

.teaser-image-full {
  height: 100%;
  min-height: 440px;
  border-right: 1px solid white;
}

.teaser-top__content {
  position: relative;
  top: 15vh;
}

.teaser-color-overlay {
  opacity: 1;
  background-repeat: no-repeat;
  height: 100%
}

.AQF-background {
  background: url("/images/home_bg.jpg");
}

.teaser-two-column {
  color: #fff;

  h1 {
    color: #fff;
  }

  hr {
    display: inline-block;
    border-color: #fff;
    margin-top: 0;
    margin-bottom: 20px;
  }

  .brand {
    text-shadow: none;
    font-weight: bold;
    margin: 0;
  }

  .teaser-two-column-content {
    padding-left: 0;

    h2 {
      color: #fff;
    }

    .btn {
      padding: 10px 20px 8px;
      margin: 2px;
    }
  }
}

.flex-vert {
  flex-direction: row;
}

@media (min-width: $screen-phone) {
  .show-on-mobile {
    display: none;
  }
}

@media (max-width: $screen-phone) {
  .hide-on-mobile {
    display: none;
  }
}


.teaser-law-suit {
  h2 {
    font-size: 12px;
    color: white;
  }

  .meta-detail {
    font-weight: normal;
    font-size: 20px;
  }
}

@media (max-width: $screen-phone) {
  .teaser-top__content {
    top: 12vh;
  }

  .brand {
    font-size: 18px;
  }
}

@media (max-width: 350px) {
  .teaser-top__content {
    top: 2vh
  }

  .teaser-cc-edit-buttons {
    margin-top: 28px;
  }
}

@media (max-width: $grid-float-breakpoint) {
  .teaser-two-column .teaser-two-column-content {
    padding-left: 15px;
    padding-right: 16px;
  }

  .container.no-padding {
    padding: 0 15px;
  }
}