$modal-md: 800px;
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$fa-icons-font-path: "~../app/Resources/assets/fonts/fa_icons/fonts";
$screen-sm: 992px;

@import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../../fonts/simpleLineIcons/variables";
@import "../../fonts/simpleLineIconsBig/variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../../../../vendor/st/flagiconcss-bundle/ST/FlagIconCssBundle/Resources/public/sass/_flag-icon";
@import "../../fonts/robotoCodensed/style";
@import "../../fonts/robotoThin/style";
@import "../../fonts/socialShareKit/style";
@import "../../fonts/simpleLineIcons/style";
@import "../../fonts/simpleLineIconsBig/style";
@import "~fontawesome-4.7/scss/font-awesome";
@import "../socialMedia/social-share-kit";
@import "../jBox-1.2.0/jBox.all.min";
@import "../dateTimePicker/jquery.datetimepicker.min";
@import "../select2/select2";
@import "../swiper/swiper";
@import "base/_maybedelete";
@import "base/_typography";
@import "components/_advertisements";
@import "components/_buttons";
@import "components/_progress_bar";
@import "components/_colors";
@import "components/_container";
@import "components/_display";
@import "components/_margins";
@import "components/_panels";
@import "components/_popovers";
@import "components/_scrolldownarrow";
@import "components/_videowrapper";
@import "components/_question_item";
@import "components/_question_page";
@import "components/_question_message";
@import "layout/_forms";
@import "layout/_header";
@import "layout/_footer";
@import "layout/_icons";
@import "layout/_lists";
@import "layout/_macros";
@import "layout/_modal";
@import "layout/_custom_grid";
@import "layout/_navigations";
@import "layout/_panels";
@import "layout/_popup";
@import "layout/_progressbar";
@import "layout/_quota";
@import "layout/_sidebar";
@import "layout/_tables";
@import "layout/_tabs";
@import "layout/_teaser";
@import "layout/_wizard";
@import "pages/_becomeaffiliate";
@import "pages/budgetTable";
@import "pages/_compensation";
@import "pages/_contactform";
@import "pages/_currentcasesgraphics";
@import "pages/_currentcasesoverview";
@import "pages/_currentnews";
@import "pages/_dataprotection";
@import "pages/_home";
@import "pages/_expertOpinion";
@import "pages/_howtovideo";
@import "pages/_landingpage";
@import "pages/_limitAccount";
@import "pages/_lawsuitproposal";
@import "pages/_listlawsuit";
@import "pages/_listsponsor";
@import "pages/_litigationCostCalculator";
@import "pages/_litigationfinancing";
@import "pages/_login";
@import "pages/_news";
@import "pages/_profile";
@import "pages/_reasons";
@import "pages/_register";
@import "pages/_registerclaim";
@import "pages/_resetpassword";
@import "pages/_service";
@import "pages/_solutions";
@import "pages/_show";
@import "pages/_sponsoringcasecockpit";
@import "pages/_subscription";
@import "pages/_supportinfo";
@import "pages/_teaserbecome";
@import "pages/_teasercasecockpit";
@import "pages/_teaserresetpassword";
@import "pages/_teasertop";
@import "pages/_transfertable";
@import "pages/_wirecard";
@import "layout";
@import "custom";
@import "variables";

html {
    height: 100%;
}

body {
    position: relative;
    min-height: 100%;
}

