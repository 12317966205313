.question__item {
    margin: 12px 0 24px 2px;

    .question-item__title {
        margin: 6px 0;
    }

    .question-item__options {
        display: flex;
        flex-direction: column;

        .question-item__option {
            display: flex;
            align-items: center;
            margin: 6px 0;

            .question-item__option--text {
                margin: 0 4px;
            }
        }

        .question__item__option--with-input {
            flex-wrap: wrap;
        }
    }

    .question-item__inputs {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .question-item__input {
            width: calc(50% - 24px);
            margin: 6px 0;

            .question-item__input--text {
                display: inline-block;
                margin-right: 4px;
                width: calc(40% - 4px);
            }

            .question-item__input--field {
                vertical-align: top;
                display: inline-block;
                width: calc(60% - 8px);
            }
        }
    }

    .question-item__note {
        padding: 8px;
        border: 1px solid $brand-primary;
    }

    .question-item__number--unit {
        display: inline-block;
        padding: 3px;
        border-radius: 4px;
        background: #6e6e6e;
        color: white;
        font-weight: bold;
    }

    .question-item__textarea {
        width: 100%;
        height: 128px;
        resize: vertical;
    }

    .question-item__label {
        width: 100%;
    }

    input[type=radio], input[type=checkbox], input[type=range] {
        margin: 3px;

        &:invalid {

            &:after {
                content: '';
                display: block;
                position: relative;
                top: -2px;
                left: -2px;
                background: $brand-primary;
                width: 6px;
                height: 6px;
                border-radius: 3px;
            }
        }
    }

    .question-item__textarea, select, input[type=text], input[type=email], input[type=number], input[type=time], input[type=date] {
        &:invalid {
            border: 1px solid $brand-primary;
        }
    }
}

@media (max-width: 512px) {
    .question-item__option--with-input {
        flex-wrap: wrap;

        .question-item__option--wrapable {
            display: block;
            width: 100%;
            margin-top: 8px;
        }
    }

    .question-item__option--list-text {
        margin-top: 8px;
        flex-basis: 100%;
    }

    .question__item .question-item__inputs {
        display: flex;
        flex-direction: column;

        .question-item__input {
            width: calc(100% - 12px);
            margin: 6px 0;
        }
    }
}