.message {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 64px;

    .message__image--wrap {

        .message__image--svg {
            height: 64px;
            width: 64px;
            fill: $brand-primary;
        }
    }

    .message__text {
        display: block;
        margin-left: 24px;

        h3 {
            display: block;
            color: $brand-primary;
            margin: 0 0 8px 0;
        }

        .message__desc {
            display: block;
        }
    }
}