.quota_wrap {
    width: 100%;
    overflow-y: auto;
}

.quota_body {
    width: 100%;
    min-width: 920px;
    border: 1px solid rgb(173, 184, 195);

    input[type=checkbox], input[type=radio] {
        opacity: 0;

        &:checked {
            ~ .quota_collapse_box {
                height: inherit;
            }
        }
    }
}

.quota_head {
    background: #005880;
    color: white;

    .quota_title {
        text-align: center;
        padding: 12px 0;
    }
}

.quota_upper {
    border-bottom: 1px solid rgb(173, 184, 195);
}

.quota_left {
    border-right: 1px solid rgb(173, 184, 195);
}

.quota_titles {
    border-bottom: 1px solid rgb(173, 184, 195);
}

.quota_sub_titles {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;

    .quota_sub_title {
        text-align: center;
        color: $brand-primary;
        padding: 12px 0;

        &.fix_32 {
            width: 32.5%;
        }
    }

    .quota_sub_title_space {
        width: 32px;
    }
}

.quota_collapse_primary {
    margin: 4px;
    margin-top: 24px;
    text-align: left;
}

.quota_collapse_headline {
    font-size: 16px;
    cursor: pointer;

    .quota_collapse_btn {
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        width: 24px;
        height: 24px;
        display: inline-block;
        border-radius: 18px;
        color: white;
        padding: 5px;

        &.green {
            background-color: #7BB700;
        }

        &.red {
            background-color: #C00000;
        }

        &:hover {
            opacity: .7;
            text-decoration: none;
        }
    }

    .quota_collapse_title {
        margin-left: 6px;

        &.green {
            color: #7BB700;
        }

        &.red {
            color: #C00000;
        }
    }

    .quota_collapse_title_note {
        margin-left: 6px;
    }
}

.quota_victim_value {
    display: inline-block;
    width: 32.5%;
    font-size: 18px;
    text-align: center;
    color: $brand-primary;
}

.quota_collapse_box {
    height: 0px;
    overflow: hidden;
    margin: 12px 0 12px 32px;
    transition: height .2s;
}

.quota_sponsor_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px 56px -1px 12px;
    font-size: 16px;
    border-bottom: 1px solid rgb(173, 184, 195);
    border-top: 1px solid rgb(173, 184, 195);

    .quota_sponsor_row_percent {
        margin-left: 8px;

        &.green {
            color: #7BB700;
        }

        &.red {
            color: #C00000;
        }
    }

    .quota_sponsor_row_ammount {
    }

    .quota_sponsor_row_date {
    }

    .quota_sponsor_row_id {
    }

    .quota_sponsor_row_split {
        width: 142px;
        font-size: 12px;
    }
}

.quota_btn {
    height: 24px;
    width: 24px;
    background-color: white;
    border: 1px solid #636363;
    border-radius: 12px;
    color: #636363;
    font-size: 18px;
    line-height: 18px;
    text-decoration: none;
    text-align: center;
    padding: 0;
    outline: none;
    cursor: pointer;
    transition: border .2s;

    &:hover {
        border: 2px solid #636363;
    }
}

.quota_sponsor_edit {
    position: relative;
    float: right;
    bottom: 30px;
    margin-right: 8px;
    line-height: 22px;
    text-decoration: none !important;
}

.disabled_quota_edit {
    color: #c00000 !important;
    border: 1px solid #c00000 !important;
}

.quota_refresh {
    display: block;
    margin: 12px 12px 12px auto;
}

.quota_sum {
    display: flex;
    flex-direction: row;
    color: $brand-primary;
    font-size: 18px;
    margin-left: 32px;

    div {
        margin-left: 54px;
    }
}

.quota_legend {
    margin-top: 24px;
}

.quota_legend_node {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px;
}

.quota_legend_badge {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    margin: 8px;
    transition: transform .2s;

    &.premium {
        background-color: $brand-primary;
    }

    &.affected {
        background-color: #5EB6E4;
    }
    &.success {
        background-color: rgb(162, 217, 100);
    }

    &.danger {
        background-color: rgb(213, 3, 29);
    }

}

.quota_legend_badge_margin{
    margin: 8px 65px 8px 12px;
}