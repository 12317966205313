.compensation-title {
  margin-bottom: 20px;
}

.compensation-table-borders {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.compensation-table-half-cell {
  width: 50%;
}

.compensation-elements-margin-top {
  margin-top: 30px;
}

.compensation-disclaimer-margin-bottom {
  margin-bottom: 2px;
}

.compensation-subtitle-margin-top {
  margin-top: 60px;
}