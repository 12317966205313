
/*
 * Table
 */
.table-styled-quotenbalancing {
  thead td {
    background: rgba(0, 88, 128, 0.1);
    font-size: 15px;
    border: 1px solid #ddd;
    text-align: center;
    border-bottom: 1px solid #ddd;
    border-top: solid;
  }

  tbody td {
    font-size: 14px;
    border: none !important;
  }

  tfoot td {
    background: rgba(0, 88, 128, 0.1);
    font-size: 15px;
    border: 1px solid #cecece;
    text-align: center;
    border-top: solid;
    border-bottom: 1px solid;
  }
}

.table-styled {
  thead td {
    background: rgba(0, 88, 128, 0.1);
    font-size: 15px;
    border: 1px solid #cecece;
    text-align: center;
    border-bottom: solid;
    border-top: solid;
  }

  tbody td {
    font-size: 14px;
    border: none;
  }

  tfoot td {
    background: rgba(0, 88, 128, 0.1);
    font-size: 15px;
    border: 1px solid #cecece;
    text-align: center;
    border-top: solid;
    border-bottom: solid;
  }
}

.table-scrollable {
  overflow-x: auto;
}

.table-Victim {
  background-color: white;
  color: #0d6aad;
  font-size: 20px;
  text-align: center;
}

.table-layout {
  display: table;
  width: 100%;

  .table-cell {
    display: table-cell;
    vertical-align: top;
    padding: 15px;

    &.fixed-width-100 {
      width: 100px;
    }

    &.fixed-width-150 {
      width: 150px;
    }
  }
}

td > span2 {
  float: right;
  font-weight: normal;
  font-size: 16px;
}

.dataTables_filter {
  display: none;
}