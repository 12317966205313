/*!
 * Social Share Kit v1.0.7 (http://socialsharekit.com)
 * Copyright 2015 Social Share Kit / Kaspars Sprogis.
 * Licensed under Creative Commons Attribution-NonCommercial 3.0 license:
 * https://github.com/darklow/social-share-kit/blob/master/LICENSE
 * ---
 */

.ssk:before {
  display: inline-block;
  font-family: "social-share-kit" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ssk-facebook:before {
  content: "a";
  text-indent: 4px;
  margin-right: -4px
}

.ssk-twitter:before {
  content: "b"
}

.ssk-google-plus:before {
  content: "v"
}

.ssk-google-plus-old:before {
  content: "c"
}

.ssk-email:before {
  content: "d";
  top: -1px;
  position: relative
}

.ssk-pinterest:before {
  content: "e";
  transform: scale(.9)
}

.ssk-tumblr:before {
  content: "f"
}

.ssk-linkedin:before {
  content: "g"
}

.ssk-github:before {
  content: "h"
}

.ssk-vk:before {
  content: "i"
}

.ssk-instagram:before {
  content: "j"
}

.ssk-amazon:before {
  content: "k"
}

.ssk-skype:before {
  content: "s"
}

.ssk-youtube:before {
  content: "x"
}

.ssk-vimeo:before {
  content: "u"
}

.ssk-ebay:before {
  content: "p"
}

.ssk-apple:before {
  content: "l"
}

.ssk-behance:before {
  content: "q"
}

.ssk-dribble:before {
  content: "n"
}

.ssk-android:before {
  content: "o"
}

.ssk-whatsapp:before {
  content: "m"
}

.ssk-reddit:before {
  content: "r"
}

.ssk-reddit2:before {
  content: "t"
}

.ssk-link:before {
  content: "w"
}

.ssk-buffer:before {
  content: "\79";
  transform: scale(.85)
}

.ssk {
  background-color: #757575;
  color: white;
  display: inline-block;
  font-size: 22px;
  line-height: 1px;
  margin-right: 2px;
  margin-bottom: 2px;
  padding: 7px;
  text-align: center;
  text-decoration: none;
  transition: background-color .1s;
  -webkit-transition: background-color .1s;
  -moz-transition: background-color .1s;
  -ms-transition: background-color .1s;
  -o-transition: background-color .1s
}

.ssk:before, .ssk .glyphicon, .ssk .fa {
  position: relative;
  font-size: 22px;
  top: 0;
  vertical-align: middle
}

.ssk.ssk-xs, .ssk-xs > .ssk {
  padding: 4px
}

.ssk.ssk-xs:before, .ssk-xs > .ssk:before, .ssk.ssk-xs .glyphicon, .ssk-xs > .ssk .glyphicon, .ssk.ssk-xs .fa, .ssk-xs > .ssk .fa {
  font-size: 15px
}

.ssk.ssk-sm, .ssk-sm > .ssk {
  padding: 5px
}

.ssk.ssk-sm:before, .ssk-sm > .ssk:before, .ssk.ssk-sm .glyphicon, .ssk-sm > .ssk .glyphicon, .ssk.ssk-sm .fa, .ssk-sm > .ssk .fa {
  font-size: 20px
}

.ssk.ssk-lg, .ssk-lg > .ssk {
  padding: 9px
}

.ssk.ssk-lg:before, .ssk-lg > .ssk:before, .ssk.ssk-lg .glyphicon, .ssk-lg > .ssk .glyphicon, .ssk.ssk-lg .fa, .ssk-lg > .ssk .fa {
  font-size: 28px
}

.ssk:last-child {
  margin-right: 0
}

.ssk:hover {
  background-color: #424242
}

.ssk:hover, .ssk:focus {
  color: #fff;
  text-decoration: none
}

.ssk.ssk-round, .ssk-round .ssk {
  border-radius: 50%
}

.ssk.ssk-round:before, .ssk-round .ssk:before {
  text-indent: 0;
  margin-right: 0
}

.ssk.ssk-rounded, .ssk-rounded .ssk {
  border-radius: 15%
}

.ssk.ssk-icon {
  color: #757575;
  padding: 2px;
  font-size: 24px
}

.ssk.ssk-icon, .ssk.ssk-icon:hover {
  background-color: transparent
}

.ssk.ssk-icon:hover {
  color: #424242
}

.ssk.ssk-icon.ssk-xs, .ssk-xs > .ssk.ssk-icon {
  font-size: 16px
}

.ssk.ssk-icon.ssk-sm, .ssk-sm > .ssk.ssk-icon {
  font-size: 20px
}

.ssk.ssk-icon.ssk-lg, .ssk-lg > .ssk.ssk-icon {
  font-size: 28px
}

.ssk.ssk-text {
  overflow: hidden;
  font-size: 17px;
  line-height: normal;
  padding-right: 10px
}

.ssk.ssk-text:before, .ssk.ssk-text .glyphicon, .ssk.ssk-text .fa {
  margin: -7px 10px -7px -7px;
  padding: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  vertical-align: bottom;
  text-indent: 0
}

.ssk-block .ssk.ssk-text {
  display: block;
  margin-right: 0;
  text-align: left
}

.ssk.ssk-text.ssk-xs, .ssk-xs > .ssk.ssk-text {
  font-size: 12px;
  padding-right: 6px
}

.ssk.ssk-text.ssk-xs:before, .ssk-xs > .ssk.ssk-text:before, .ssk.ssk-text.ssk-xs .glyphicon, .ssk-xs > .ssk.ssk-text .glyphicon, .ssk.ssk-text.ssk-xs .fa, .ssk-xs > .ssk.ssk-text .fa {
  margin: -4px 6px -4px -4px;
  padding: 4px
}

.ssk.ssk-text.ssk-sm, .ssk-sm > .ssk.ssk-text {
  font-size: 16px;
  padding-right: 7px
}

.ssk.ssk-text.ssk-sm:before, .ssk-sm > .ssk.ssk-text:before, .ssk.ssk-text.ssk-sm .glyphicon, .ssk-sm > .ssk.ssk-text .glyphicon, .ssk.ssk-text.ssk-sm .fa, .ssk-sm > .ssk.ssk-text .fa {
  margin: -5px 7px -5px -5px;
  padding: 5px
}

.ssk.ssk-text.ssk-lg, .ssk-lg > .ssk.ssk-text {
  font-size: 22px;
  padding-right: 13px
}

.ssk.ssk-text.ssk-lg:before, .ssk-lg > .ssk.ssk-text:before, .ssk.ssk-text.ssk-lg .glyphicon, .ssk-lg > .ssk.ssk-text .glyphicon, .ssk.ssk-text.ssk-lg .fa, .ssk-lg > .ssk.ssk-text .fa {
  margin: -9px 13px -9px -9px;
  padding: 9px
}

.ssk-group, .ssk-sticky {
  font-size: 0
}

.ssk-sticky {
  top: 0;
  position: fixed;
  z-index: 2000
}

.ssk-sticky .ssk {
  transition: padding .1s ease-out;
  -webkit-transition: padding .1s ease-out;
  -moz-transition: padding .1s ease-out;
  -ms-transition: padding .1s ease-out;
  -o-transition: padding .1s ease-out;
  margin: 0
}

@media (min-width: 1060px) {
  .ssk-sticky.ssk-left .ssk, .ssk-sticky.ssk-right .ssk {
    display: block;
    clear: both
  }
  .ssk-sticky.ssk-left.ssk-center, .ssk-sticky.ssk-right.ssk-center {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%)
  }
  .ssk-sticky.ssk-left {
    left: 0
  }
  .ssk-sticky.ssk-left .ssk {
    float: left
  }
  .ssk-sticky.ssk-left .ssk:hover {
    padding-left: 15px
  }
  .ssk-sticky.ssk-right {
    right: 0
  }
  .ssk-sticky.ssk-right .ssk {
    float: right
  }
  .ssk-sticky.ssk-right .ssk:hover {
    padding-right: 15px
  }
}

.ssk-sticky.ssk-bottom {
  font-size: 0;
  top: auto;
  bottom: 0
}

.ssk-sticky.ssk-bottom.ssk-center {
  left: 50%;
  right: auto;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%)
}

.ssk-sticky.ssk-bottom .ssk {
  vertical-align: bottom
}

.ssk-sticky.ssk-bottom .ssk:hover {
  padding-bottom: 15px
}

.ssk-sticky.ssk-round.ssk-xs .ssk:hover {
  padding: 8px
}

.ssk-sticky.ssk-round.ssk-sm .ssk:hover {
  padding: 9px
}

.ssk-sticky.ssk-round .ssk:hover {
  padding: 11px
}

.ssk-sticky.ssk-round.ssk-lg .ssk:hover {
  padding: 13px
}

@media (max-width: 1059px) {
  .ssk-sticky {
    left: 0;
    right: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap
  }
  .ssk-sticky.ssk-sticky-hide-xs {
    display: none !important
  }
  .ssk-sticky .ssk {
    flex: 1;
    width: auto
  }
  .ssk-sticky .ssk .ssk-num {
    display: none
  }
}

.ssk-count {
  padding-top: 20px
}

.ssk-count .ssk {
  position: relative
}

.ssk-count .ssk-num {
  border-radius: 4px;
  color: #8f8f8f;
  background-color: rgba(50, 50, 50, 0.03);
  display: block;
  font-size: 12px;
  left: 0;
  line-height: 20px;
  position: absolute;
  right: 0;
  text-align: center;
  top: -20px
}

@media (min-width: 1060px) {
  .ssk-count.ssk-sticky {
    padding-top: 0
  }
  .ssk-count.ssk-sticky.ssk-left .ssk-num, .ssk-count.ssk-sticky.ssk-right .ssk-num {
    top: 20%;
    background-color: transparent
  }
  .ssk-count.ssk-sticky.ssk-left .ssk-num {
    left: 100%;
    margin-left: 5px
  }
  .ssk-count.ssk-sticky.ssk-right .ssk-num {
    right: 115%;
    margin-left: -100%;
    text-align: right
  }
}

.ssk-facebook {
  background-color: #3B5998
}

.ssk-grayscale > .ssk-facebook {
  background-color: #757575
}

.ssk-facebook:hover {
  background-color: #2d4373
}

.ssk-facebook:hover {
  background-color: #2d4373
}

.ssk-grayscale > .ssk-facebook:hover {
  background-color: #3B5998
}

.ssk-facebook.ssk-icon {
  color: #3B5998
}

.ssk-facebook.ssk-icon:hover {
  color: #2d4373
}

.ssk-facebook.ssk-icon:before {
  text-indent: 0;
  margin-right: 0
}

.ssk-twitter {
  background-color: #1DA1F2
}

.ssk-grayscale > .ssk-twitter {
  background-color: #757575
}

.ssk-twitter:hover {
  background-color: #0c85d0
}

.ssk-twitter:hover {
  background-color: #0c85d0
}

.ssk-grayscale > .ssk-twitter:hover {
  background-color: #1DA1F2
}

.ssk-twitter.ssk-icon {
  color: #1DA1F2
}

.ssk-twitter.ssk-icon:hover {
  color: #0c85d0
}

.ssk-google-plus {
  background-color: #EA4335
}

.ssk-grayscale > .ssk-google-plus {
  background-color: #757575
}

.ssk-google-plus:hover {
  background-color: #d62516
}

.ssk-google-plus:hover {
  background-color: #d62516
}

.ssk-grayscale > .ssk-google-plus:hover {
  background-color: #EA4335
}

.ssk-google-plus.ssk-icon {
  color: #EA4335
}

.ssk-google-plus.ssk-icon:hover {
  color: #d62516
}

.ssk-pinterest {
  background-color: #BD081C
}

.ssk-grayscale > .ssk-pinterest {
  background-color: #757575
}

.ssk-pinterest:hover {
  background-color: #8c0615
}

.ssk-pinterest:hover {
  background-color: #8c0615
}

.ssk-grayscale > .ssk-pinterest:hover {
  background-color: #BD081C
}

.ssk-pinterest.ssk-icon {
  color: #BD081C
}

.ssk-pinterest.ssk-icon:hover {
  color: #8c0615
}

.ssk-tumblr {
  background-color: #395773
}

.ssk-grayscale > .ssk-tumblr {
  background-color: #757575
}

.ssk-tumblr:hover {
  background-color: #283d51
}

.ssk-tumblr:hover {
  background-color: #283d51
}

.ssk-grayscale > .ssk-tumblr:hover {
  background-color: #395773
}

.ssk-tumblr.ssk-icon {
  color: #395773
}

.ssk-tumblr.ssk-icon:hover {
  color: #283d51
}

.ssk-email {
  background-color: #757575
}

.ssk-grayscale > .ssk-email {
  background-color: #757575
}

.ssk-email:hover {
  background-color: #5b5b5b
}

.ssk-email:hover {
  background-color: #5b5b5b
}

.ssk-grayscale > .ssk-email:hover {
  background-color: #757575
}

.ssk-grayscale > .ssk-email:hover {
  background-color: #5b5b5b
}

.ssk-email.ssk-icon {
  color: #757575
}

.ssk-email.ssk-icon:hover {
  color: #5b5b5b
}

.ssk-vk {
  background-color: #54769a
}

.ssk-grayscale > .ssk-vk {
  background-color: #757575
}

.ssk-vk:hover {
  background-color: #425d79
}

.ssk-vk:hover {
  background-color: #425d79
}

.ssk-grayscale > .ssk-vk:hover {
  background-color: #54769a
}

.ssk-vk.ssk-icon {
  color: #54769a
}

.ssk-vk.ssk-icon:hover {
  color: #425d79
}

.ssk-linkedin {
  background-color: #1c87bd
}

.ssk-grayscale > .ssk-linkedin {
  background-color: #757575
}

.ssk-linkedin:hover {
  background-color: #156791
}

.ssk-linkedin:hover {
  background-color: #156791
}

.ssk-grayscale > .ssk-linkedin:hover {
  background-color: #1c87bd
}

.ssk-linkedin.ssk-icon {
  color: #1c87bd
}

.ssk-linkedin.ssk-icon:hover {
  color: #156791
}

.ssk-whatsapp {
  background-color: #34AF23
}

.ssk-grayscale > .ssk-whatsapp {
  background-color: #757575
}

.ssk-whatsapp:hover {
  background-color: #27851a
}

.ssk-whatsapp:hover {
  background-color: #27851a
}

.ssk-grayscale > .ssk-whatsapp:hover {
  background-color: #34AF23
}

.ssk-whatsapp.ssk-icon {
  color: #34AF23
}

.ssk-whatsapp.ssk-icon:hover {
  color: #27851a
}

.ssk-reddit {
  background-color: #5f99cf
}

.ssk-grayscale > .ssk-reddit {
  background-color: #757575
}

.ssk-reddit:hover {
  background-color: #3a80c1
}

.ssk-reddit:hover {
  background-color: #3a80c1
}

.ssk-grayscale > .ssk-reddit:hover {
  background-color: #5f99cf
}

.ssk-reddit.ssk-icon {
  color: #5f99cf
}

.ssk-reddit.ssk-icon:hover {
  color: #3a80c1
}

.ssk-reddit2 {
  background-color: #5f99cf
}

.ssk-grayscale > .ssk-reddit2 {
  background-color: #757575
}

.ssk-reddit2:hover {
  background-color: #3a80c1
}

.ssk-reddit2:hover {
  background-color: #3a80c1
}

.ssk-grayscale > .ssk-reddit2:hover {
  background-color: #5f99cf
}

.ssk-reddit2.ssk-icon {
  color: #5f99cf
}

.ssk-reddit2.ssk-icon:hover {
  color: #3a80c1
}

.ssk-buffer {
  background-color: #323B43
}

.ssk-grayscale > .ssk-buffer {
  background-color: #757575
}

.ssk-buffer:hover {
  background-color: #1c2126
}

.ssk-buffer:hover {
  background-color: #1c2126
}

.ssk-grayscale > .ssk-buffer:hover {
  background-color: #323B43
}

.ssk-buffer.ssk-icon {
  color: #323B43
}

.ssk-buffer.ssk-icon:hover {
  color: #1c2126
}

.ssk-turquoise {
  background-color: #1abc9c
}

.ssk-turquoise:hover {
  background-color: #148f77
}

.ssk-emerald {
  background-color: #2ecc71
}

.ssk-emerald:hover {
  background-color: #25a25a
}

.ssk-peter-river {
  background-color: #3498db
}

.ssk-peter-river:hover {
  background-color: #217dbb
}

.ssk-belize-hole {
  background-color: #2980b9
}

.ssk-belize-hole:hover {
  background-color: #20638f
}

.ssk-amethyst {
  background-color: #9b59b6
}

.ssk-amethyst:hover {
  background-color: #804399
}

.ssk-wisteria {
  background-color: #8e44ad
}

.ssk-wisteria:hover {
  background-color: #703688
}

.ssk-wet-asphalt {
  background-color: #34495e
}

.ssk-wet-asphalt:hover {
  background-color: #222f3d
}

.ssk-midnight-blue {
  background-color: #2c3e50
}

.ssk-midnight-blue:hover {
  background-color: #1a242f
}

.ssk-green-sea {
  background-color: #16a085
}

.ssk-green-sea:hover {
  background-color: #107360
}

.ssk-nephritis {
  background-color: #27ae60
}

.ssk-nephritis:hover {
  background-color: #1e8449
}

.ssk-sunflower {
  background-color: #f1c40f
}

.ssk-sunflower:hover {
  background-color: #c29d0b
}

.ssk-orange {
  background-color: #f39c12
}

.ssk-orange:hover {
  background-color: #c87f0a
}

.ssk-carrot {
  background-color: #e67e22
}

.ssk-carrot:hover {
  background-color: #bf6516
}

.ssk-pumpkin {
  background-color: #d35400
}

.ssk-pumpkin:hover {
  background-color: #a04000
}

.ssk-alizarin {
  background-color: #e74c3c
}

.ssk-alizarin:hover {
  background-color: #d62c1a
}

.ssk-pomegranate {
  background-color: #c0392b
}

.ssk-pomegranate:hover {
  background-color: #962d22
}

.ssk-clouds {
  background-color: #cfd9db
}

.ssk-clouds:hover {
  background-color: #b1c2c6
}

.ssk-concrete {
  background-color: #95a5a6
}

.ssk-concrete:hover {
  background-color: #798d8f
}

.ssk-silver {
  background-color: #bdc3c7
}

.ssk-silver:hover {
  background-color: #a1aab0
}

.ssk-asbestos {
  background-color: #7f8c8d
}

.ssk-asbestos:hover {
  background-color: #667273
}

.ssk-dark-gray {
  background-color: #555
}

.ssk-dark-gray:hover {
  background-color: #3b3b3b
}

.ssk-black {
  background-color: #333
}

.ssk-black:hover {
  background-color: #1a1a1a
}