.overview-title-margin {
  margin: 30px 0px 20px 0px;
}

.overview-icon-size {
  font-size: 72px;
}

.overview-icon-div-padding {
  padding: 15px 0px 20px 0px;
}

.home-title-margin {
   margin: 30px 0 40px 0 !important;
 }