.affiliate-column-padding {
  padding: 20px;
}

.affiliate-partner-wrapper {
  height: 650px;
  border: 1px solid #ddd;
  margin-top: 30px;
}

.affiliate-partner-intro {
  height: 44px;
}

.affiliate-partner-image-wrapper {
  height: 140px;
  margin-bottom: 20px;
  overflow: hidden;
}

.affiliate-partner-image {
  cursor: pointer;
  height: auto;
  width: 100%;
}

.affiliate-partner-list {
  margin-bottom: 30px;
  margin-left: -22px;
}