.teaser-top-main-slogan {
  font-size: 52px;
  color: #f0f0f0;
}

.teaser-top-main-button-div {
  margin-top: 45px;
  padding-left: 0;
}

.teaser-top-main-button-border {
  border-left: 1px solid #005880;
}

.teaser-btn-action{
  margin-bottom: 20px;
}

.teaser-color-overlay {
  padding: 0;
  width: 100%;
}

/* responsive button-group on homepage */
@media (max-width: 1199px) {
  .btn-group.my-btn-group-responsive > .btn {
    display: block;
    width: 100%;
  }

  /* making the border-radius correct */
  .btn-group.my-btn-group-responsive > .btn:first-child {
    border-radius: 6px 6px 0 0;
    border-left: 1px solid #005880;
    border-bottom: 1px solid #005880;
  }
  .btn-group.my-btn-group-responsive > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 6px;
  }
  .btn-group.my-btn-group-responsive > .btn:last-child:not(:first-child) {
    border-radius: 0 0 6px 6px;
    border-top: 1px solid #005880;
  }

  /* fixing margin */
  .btn-group.my-btn-group-responsive .btn + .btn {
    margin-left: 0;
  }
}

@media (max-width: $screen-phone) {
  .teaser-top-main-slogan {
    font-size: 32px;
  }

  .teaser-top-main-button-div {
    margin-top: 15px;
  }
}

.teaser-top-main-button {
  display: block;
  border-radius: 0;
  color: #fff;
  text-align: left;
  width: 100%;
  font-size: 16px;

  > .fa {
    width: 40px;
  }
  &:hover {
    padding-bottom: 18px;
    padding-top: 18px;
  }

}

.car-description{
  width: auto;
  margin-left: 15%;

  > .brand {
    color: #f0f0f0;
  }
}

.btn-top {
  background: #19427B;
  &:hover {
    background: #112c53;
  }
}
.btn-middle{
  background: #757575;
  &:hover {
    background: #656565;
  }
}
.btn-bottom{
  background: #323232;
  &:hover {
    background: #000000;
  }
}


.carousel-1 {

  > .btn {
    border-radius: 6px;
  }

  height: 100%;
  color: #fff;
  .content-slider {
    position: relative;
    width: 100%;
    height: 100%;
    input {display: none;}
    .slider {
      position: relative;
      width: inherit;
      height: inherit;
      overflow: hidden;
      .banner {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 0;
        width: inherit;
        height: inherit;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        transition: all 0.7s ease;
        margin-left: 0;
        .banner-inner-wrapper {
          height: 100%;
          padding-top: 6em;
          box-sizing: border-box;
          h2 {
            padding-bottom: 0.3em;
            font-weight: 400;
            font-size: 2.5em;
            text-transform: none;
          }
          h1 {
            line-height: 120%;
            overflow: visible;
          }
          .line {
            display: block;
            width: 4em;
            height: 0.1875em;
            margin: 2.5em auto;
            background: #fff;
          }
          .learn-more-button {
            padding-bottom: 5em;
            z-index: 15 !important;
            a {
              padding: 0.5em 2em;
              text-align: center;
              font-size: 0.875em;
              color: #fff;
              text-transform: uppercase;
              border: 0.1875em solid #fff;
              &:hover {
                color: #e88382;
                border-color: #e88382;
                transition: 0.3s;
              }}
          }
        }
      }

      .top-banner-1 {}
      .top-banner-2 {}
      .top-banner-3 {}
      .top-banner-4 {}
    }
    .nav-buttons{
      position: absolute;
      bottom: 0.5em;
      width: 100%;
      z-index: 6;
      text-align: center;
    }

    nav {
      position: absolute;
      /*top: 0.5em;*/
      bottom: 0.5em;
      width: 100%;
      z-index: 6;
      text-align: center;
      .controls {
        display: block;
        width: 80%;
        margin: 0 auto;
        color: #fff;

        label {
          position: relative;
          display: inline-block;
          width: 20%;
          height: 3.1em;
          overflow: hidden;
          margin: 0 1em;
          /* padding-top: 0; */
          padding-top: 1em;
          text-align: left;
          text-transform: uppercase;
          font-size: 1em;
          color: #f6eac5;
          font-weight: 400;
          cursor: pointer;
          transition: all .3s;
          .progressbar {
            position: absolute;
            /*bottom: 0;*/
            top: 0;
            left: 0;
            height: 3px;
            width: 100%;
            background: #f6eac5;
            z-index: 100;
            .progressbar-fill {
              position: inherit;
              width: inherit;
              height: inherit;
              margin-left: -100%;
              background: #e88382;
            }
          }
          span {
            font-size: 1.4em;
            font-weight: 700;
          }
          &:hover {color: #e88382;}
        }
        .car-control {
          color: #f0f0f0;
          &:hover {
            color: #f0f0f0;
            text-decoration: none;
            filter: alpha(opacity=90);
            outline: 0;
            opacity: .7;
          }
        }
      }
    }
    .banner1:checked ~ .slider .top-banner-4 {
      transform: translate3d(-100%, 0, 0);
    }
    .banner2:checked ~ .slider .top-banner-1 {
      transform: translate3d(-100%, 0, 0);
    }
    .banner3:checked ~ .slider .top-banner-2 {
      transform: translate3d(-100%, 0, 0);
    }
    .banner4:checked ~ .slider .top-banner-3 {
      transform: translate3d(-100%, 0, 0);
    }
    .banner1:checked ~ .slider .top-banner-1,
    .banner2:checked ~ .slider .top-banner-2,
    .banner3:checked ~ .slider .top-banner-3,
    .banner4:checked ~ .slider .top-banner-4 {
      opacity: 1;
      z-index: 1;
    }
    .banner1:checked ~ nav label:nth-of-type(1),
    .banner2:checked ~ nav label:nth-of-type(2),
    .banner3:checked ~ nav label:nth-of-type(3),
    .banner4:checked ~ nav label:nth-of-type(4) {
      cursor: default;
      color: #fff;
      transition: all 2s;
    }
    .banner1:checked ~ nav label:nth-of-type(1) .progressbar,
    .banner2:checked ~ nav label:nth-of-type(2) .progressbar,
    .banner3:checked ~ nav label:nth-of-type(3) .progressbar,
    .banner4:checked ~ nav label:nth-of-type(4) .progressbar {
      background: #fff;
    }
    .banner1:checked ~ nav label:nth-of-type(1) .progressbar-fill,
    .banner2:checked ~ nav label:nth-of-type(2) .progressbar-fill,
    .banner3:checked ~ nav label:nth-of-type(3) .progressbar-fill,
    .banner4:checked ~ nav label:nth-of-type(4) .progressbar-fill {
      animation: progressBarFill 10s linear;
    }
  }
}

/* Animations */
@keyframes progressBarFill {
  from {margin-left: -100%;}
  to {margin-left: 0;}
}

/* Media Queries */
@media only screen and (min-width: 1920px) {
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper {padding-top: 13em;}
}

@media only screen and (max-width: 1919px) {
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper {padding-top: 11em;}
}

@media only screen and (max-width: 1680px) {
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper {padding-top: 9em;}
}


@media only screen and (max-width: 1120px) {
  .carousel-1 .content-slider nav {bottom: -0.2em;}
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper {padding-top: 7em;}
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper .line {margin: 2em auto;}
}

@media only screen and (max-width: 1024px) {
  .carousel-1 .content-slider nav {bottom: 0;}
  .carousel-1 .content-slider nav .controls {width: 80%;}
}

@media only screen and (max-width: 860px) {
  .carousel-1 .content-slider nav {bottom: -1em;}
  .carousel-1 .content-slider nav .controls {width: 100%; position:absolute; bottom: 20px;}
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper h2 {font-size: 2em;}
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper h1 {font-size: 3em;}
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper {padding-top: 5em;}
}

@media only screen and (max-width: 768px) {
  .carousel-1 .content-slider nav .controls {width: 100%;}
  .carousel-1 .content-slider nav .controls label {width: 19%; font-size: 0.8em;}
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper .line {margin: 1.7em auto;}
}


@media only screen and (max-width: 480px) {
  .carousel-1 .content-slider nav {bottom: -0.5em;}
  .carousel-1 .content-slider nav .controls label {width: 40%; font-size: 0.7em;}
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper h2 {font-size: 1.5em;}
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper h1 {font-size: 3em;}
  .carousel-1 .content-slider .slider .banner .banner-inner-wrapper .line {margin: 1.7em auto;}
}

.arrowSpanLeft {
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: sdb07 2.5s infinite;
  animation: sdb07 2.5s infinite;
  box-sizing: border-box;
  cursor: pointer;
}

.ssk-teaser:hover {
  padding-right: revert;
  padding-bottom: 18px;
  padding-top: 18px;
}
.ssk-teaser {
  z-index: 8;
}
@media (max-width: 1059px) {
  .ssk-teaser {
    display: block !important;
    top: 40%;
    transform: translateX(calc(100% - 52px));
  }


}


@-webkit-keyframes sdb07 {
  0% {
    -webkit-transform: rotate(45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes sdb07 {
  0% {
    transform: rotate(45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@media screen and (max-width: 767px) {
  .arrowSpanRight, .arrowSpanLeft {display: none;}
}

.arrowSpanRight {
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  -webkit-animation: sdb08 2.5s infinite;
  animation: sdb08 2.5s infinite;
  box-sizing: border-box;
  cursor: pointer;
}

@-webkit-keyframes sdb08 {
  0% {
    -webkit-transform: rotate(-135deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-135deg) translate(-20px, 20px);
    opacity: 0;
  }
}

@keyframes sdb08 {
  0% {
    transform: rotate(-135deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-135deg) translate(-20px, 20px);
    opacity: 0;
  }
}