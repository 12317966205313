/*
 * Macros
 */
.law-suit-teaser {
  margin-bottom: 0px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);

  h3 {
    height: 60px;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .law-suit-teaser-content {
    padding: 20px 20px 10px;
    height: 260px;
    .law-suit-teaser-content-text {
      overflow: hidden;
      position: relative;
      height: 114px;

      &:after {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        content: "";
        background: linear-gradient(to top,
                rgba(255, 255, 255, 1) 5%,
                rgba(255, 255, 255, 0) 95%
        );
        pointer-events: none; /* so the text is still selectable */
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  .law-suit-teaser-subcontent {
    padding: 20px;
    background: #f6f7f9;
  }

  .legal-clarification-law-suit-teaser-values {
    //height: 100px;
    display: contents;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    clear: both;
    padding: 0 16px;
  }

  .law-suit-teaser-values {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    clear: both;
    padding: 0 16px;
  }

  .law-suit-image {
    width: 100%;
    position: relative;
    a {
      width: 100%;
      object-fit: cover;
    }
    img {
      width: 100%;
      height: 240px;
      object-fit: cover;
    }

    .law-suit-image-content-holder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 5;
      .law-suit-image-content {
        color: #fff;
        width: 100%;
        position: absolute;
        top: 50%;
        text-align: center;
        vertical-align: middle;
        transform: translateY(-50%);
        .btn {
          width: auto;
        }
        h4 {
          font-size: 24px;
          font-weight: normal;
          hyphens: auto;
        }
        .icon-vs {
          margin: 10px 0;
          background-color: #fff;
          padding: 10px 12px;
          font-size: 12px;
          .icon-vs-content {
            color: #636363;
          }
        }
      }
    }
  }

  .detail {
    font-size: 18px;

    &.legal-project-detail {
      display: flex;
      justify-content: space-between;
    }

    .detail-caption {
      font-size: 12px;
      min-height: 17px;
    }
    .legal-clarification-detail-caption {
      font-size: 14px;
      min-height: 17px;
      padding: 15px 0px 10px 0px;
    }
  }
}

.news-teaser {
  padding: 3px 20px 20px 20px;
  position: relative;

  .news-content {
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.case-cockpit-law-suit-teaser-content {
  padding: 0px 0px 0px 0px;

  .case-cockpit-law-suit-teaser-content-text {
    overflow: hidden;
    height: 60px;
    position: relative;
    padding: 0px 0px 0px 0px;

    &:after {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      content: "";
      pointer-events: none; /* so the text is still selectable */
    }

    p {
      margin-bottom: 0;
    }
  }
}

.law-firm-teaser {
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3);

  a {
    text-decoration: none;
    color: #636363;
  }

  h3 {
    text-transform: none;
    font-weight: normal;
  }
}

.law-firm-teaser-small {
  a {
    text-decoration: none;
    color: #636363;
  }

  h3 {
    text-transform: none;
    font-weight: normal;
    margin-top: 0;
  }

  .law-fields-description {
    font-weight: normal;
    font-size: 12px;
    color: rgb(147, 147, 147);
  }

  .profileBlock {
    padding-bottom: 0;
  }
}

@media (max-width: $screen-sm) {
  .icon-vs {
    top: -20px !important;
    left: calc(50% - 56px);
  }
}

.lawsuit-teaser-customisation {
  margin-bottom: 20px;
  cursor: pointer;
}


.clickableTeaserWrapper {
  position: relative !important;
}

.link-spanner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.link-spanner-disabled {
  width: 0%;
  height: 0%;
  z-index: 0;
}

.lawsuit-state-banner {
  position: relative;
  background-color:#005880;
  color: white;
  opacity: 1 !important;
  z-index: 6;
}

.lawsuit-without-target-amount-banner {
  z-index: 7;
  opacity: 1 !important;
  height: 20px;
  padding: 3px 6px 3px;
  vertical-align: middle;
  background-color: #005880;
  color: white;
  font-size: 13px;
  position: absolute;
  bottom: 0px;
}

.lawsuit-teaser-figures-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}