.lawsuit-proposal-list-padding {
  padding: 70px 0px 40px 0px;
}

.lawsuit-proposal-back-button-padding {
  padding-left: 15px;
}

.lawsuit-proposal-interest-margin-bottom {
  margin-bottom: 30px;
}

.lawsuit-proposal-interest-margin-top {
  margin-top: 30px !important;
}