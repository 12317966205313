$icomoon-font-family: "simple-line-icons-aequifin-24" !default;
$icomoon-font-path: "fonts" !default;

$icon-24-bulb-idea: "\e900";
$icon-24-check-circle-2: "\e901";
$icon-24-people-male: "\e902";
$icon-24-safe: "\e903";
$icon-24-pie-chart-2: "\e904";
$icon-24-info: "\ea26";
$icon-24-arrow-1-left: "\e805";
$icon-24-arrow-1-right: "\e806";
$icon-24-answer: "\e83e";
$icon-24-email: "\e86a";
$icon-24-letter: "\e86e";
$icon-24-message-2-alert: "\e87d";
$icon-24-auction: "\e8f1";
$icon-24-blackboard-3: "\e933";
$icon-24-nerd-glasses: "\e94f";
$icon-24-university: "\e955";
$icon-24-7-support-2: "\e957";
$icon-24-balance: "\e95b";
$icon-24-banknotes-1: "\e960";
$icon-24-money-bag-coins: "\e992";
$icon-24-money-bag: "\e993";
$icon-24-search-money: "\e99d";
$icon-24-search-stats-1: "\e99e";
$icon-24-support: "\e9a3";
$icon-24-write-2: "\ea3f";
$icon-24-check-circle: "\eb08";
$icon-24-lock: "\eb45";
$icon-24-magnifier-1: "\eb4a";
$icon-24-file-copy: "\eb9b";
$icon-24-folder-open: "\ebcb";
$icon-24-puzzle: "\ec69";
$icon-24-location-3: "\ecf4";
$icon-24-timer-2: "\ed03";
$icon-24-love: "\ed95";
$icon-24-people: "\edd2";
$icon-24-team-hierarchy: "\edd9";
$icon-24-like-2: "\ee15";
$icon-24-trophy-2: "\ee23";
$icon-24-linkedin: "\eac9";
$icon-24-linkedin2: "\eaca";
$icon-24-xing: "\ead3";
$icon-24-xing2: "\ead4";

