/*
 * Landingpage - show
 */

.landingpage-show-xing-styling {
  background: #006567;
  font-size: 28px;
}


/*
 * Landingpage - teaser
 */

.landingpage-teaser-case-phase {
  position: absolute;
  margin-left: -20px;
}

.landingpage-teaser-no-targetamount {
  position: absolute;
  margin-left: -20px;
  bottom: 0px;
}

.landingpage-teaser-no-padding-bottom {
  padding-bottom: 0;
}

.landingpage-teaser-lawfield-div {
  display: inline-block;
  height: 36px;
  margin-top: 12px;
  margin-right: 4px;
  float: left;
}

.landingpage-teaser-deadline-div {
  display: inline-block;
  margin: 12px 0;
  float: right;
}

.landingpage-teaser-deadline {
  font-size: 24px;
}

.landingpage-teaser-countdown {
  color: white;
  font-size: 18px;
  line-height: 14px;
  margin-top: 8px;
}

.landingpage-teaser-caption {
  font-size: 34px;
  hyphens: auto;
}

.landingpage-teaser-supportbutton-div {
  height: 136px;
}

.landingpage-teaser-supportneeded-div {
  line-height: 2px;
}

.landingpage-teaser-supporttotal-div {
  line-height: 12px;
}

.landingpage-teaser-supportfigures {
  font-size: 22px;
}

.landingpage-teaser-progress-div {
  margin-bottom: 10px;
}

.landingpage-video--wrap {
  padding:56.25% 0 0 0;
  position:relative;
}

.landingpage-video--img {
  width: 100%;
  height: 100%;
}

.landingpage-video--iframe {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}

