/*
 * Login Page
 */

.teaser-login-flex-styling {
  display: flex;
  flex-direction: row;
}

.border-smaller-disappear {
  display: block;
  border-right: 1px solid white;
}

.register-button-smaller-move-up {
  margin-top: 163px;
}

.login-button-smaller-move-up {
  margin-top: 40px;
}

.login-pwinput-smaller-move-up {
  margin-top: 15px;
}

.distance-top-responsive {
  top: 30%;
}

@media (max-width: 375px){
  .distance-top-responsive {
    top: 10%;
  }
  .login-subtitle-becomes-smaller {
    font-size: 12px;
  }
  .login-forgot-password-smaller {
    font-size: 12px;
  }
}

@media (max-width: 320px){
  .login-teaser-top-smaller {

    .fixed-row {
      height: 30vh;
    }
  }
}

@media (max-height: 415px) {
  .login-subtitle-becomes-smaller {
    font-size: 12px;
  }
  .login-forgot-password-smaller {
    font-size: 12px;
  }
  .login-teaser-top-smaller {

    hr {
      margin-bottom: 10px;
    }

    .fixed-row {
      height: 10vh;
    }
  }
  .login-pwinput-smaller-move-up{
    margin-top: 0px !important;
  }
  .login-button-smaller-move-up {
    margin-top: -18px !important;
  }
  .register-button-smaller-move-up {
    margin-top: 90px !important;
  }
  .border-smaller-disappear {
    border-right: none !important;
  }
}
