/*
 * Text
 */

h1, h2, h3 {
  letter-spacing: 1px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: normal;
  color: rgb(75, 75, 75);
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
  font-weight: normal;
}

hr {
  border-width: 6px 0 0;
  width: 100px;
}

.no-letter-spacing {
  letter-spacing: 0px;
}

.disclaimer-text {
  font-size: 8px
}

.white-text {
  color: #fff;

  h1, h2, h3 {
    color: #fff;
  }
}

.brand {
  /*color: #fff;*/
  color: #0d6aad;
  font-size: 20px;
  margin: 17px 0;
}

.login-form-link {
  color: #fff;
  margin-bottom: 10px;
  font-weight: normal;
  display: inline-block;
  &:hover {
    color: #fff;
  }
}

#cookie_policy_link {
  color: white;
  text-decoration: underline
}

.no-decoration {
  text-decoration: none;
}

a.no-decoration {
  color: #000000;
}

.fictitious-platform-text {
  font-size: 13px;
}

.javascript-enable-notification-font {
  font-size: 30px;
}

.larger-text-size {
  font-size: 16px;
}

.standard-text-size {
  font-size: 12px;
}

.small-text-size {
  font-size: 8px;
}

.text-center {
  text-align: center;
}

/*
 * SEO Optimisation Classes
 */

//old-h3
.adult {
  font-size: 18px;
  font-weight: normal;
}

//remove-h2-tag
.baby {
  font-size: 24px;
  letter-spacing: 1px;
}

//old-strong
.twins {
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #636363;
  line-height: 1.42857;
}