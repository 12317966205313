$icomoon-font-family: "simple-line-icons-aequifin-48" !default;
$icomoon-font-path: "fonts" !default;

$icon-48-people-male: "\e900";
$icon-48-safe: "\e901";
$icon-48-pie-chart-2: "\e902";
$icon-48-check-circle-2: "\e903";
$icon-48-bulb-idea: "\e904";
$icon-48-arrow-1-left: "\e805";
$icon-48-arrow-1-right: "\e806";
$icon-48-answer: "\e83e";
$icon-48-email: "\e86c";
$icon-48-letter: "\e870";
$icon-48-message-2-alert: "\e87f";
$icon-48-blackboard-3: "\e8a2";
$icon-48-nerd-glasses: "\e8be";
$icon-48-university: "\e8c4";
$icon-48-write-2: "\e950";
$icon-48-check-circle: "\e965";
$icon-48-lock: "\e9a2";
$icon-48-magnifier-1: "\e9a7";
$icon-48-file-copy: "\e9fd";
$icon-48-folder-open: "\ea44";
$icon-48-7-support-2: "\ea50";
$icon-48-balance: "\ea54";
$icon-48-banknotes-1: "\ea59";
$icon-48-money-bag-coins: "\ea8b";
$icon-48-money-bag: "\ea8c";
$icon-48-search-money: "\ea96";
$icon-48-search-stats: "\ea97";
$icon-48-support: "\ea9c";
$icon-48-auction: "\eb6d";
$icon-48-puzzle: "\ec88";
$icon-48-location-3: "\ed13";
$icon-48-timer-2: "\ed22";
$icon-48-love: "\edb2";
$icon-48-people: "\edf1";
$icon-48-team-hierarchy: "\edf8";
$icon-48-like-2: "\ee34";
$icon-48-trophy-2: "\ee42";

