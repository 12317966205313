.panel-heading-primary-styling {
  cursor: pointer;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  background-color: #015781 !important;
  color: white !important;
}

.panel-heading-white-styling {
  cursor: pointer;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}