.howtovideo-margin-and-padding-bottom {
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.howtovideo-border-bottom-primary {
  display: inline-block;
  border-bottom: 1px solid #636363;
}

.howtovideo-subtitle-margin-bottom {
  margin-bottom: 20px;
}