/*
 * Panels
 */
.panel {
  width: 100%
}

.panel-colored {
  .panel-heading {
    background: rgb(173, 184, 195);
    color: #fff;
  }
}

.panel-grey {
  .panel-heading {
    background: #cecece;
  }
  border-color: #cecece;
}