$grid-gutter-width: 30px !default;

$brand-primary: #005880;

$bg-secondary: #f9fbfb;

$border-radius-base: 20px;

$grid-float-breakpoint: 992px;

