.qb-explain-modal-body-padding {
  padding: 0px;
}

.qb-explain-modal-inner-padding {
  padding: 30px 50px 30px 50px;
}

.qb-explain-modal-caption {
  position: relative;
  left: auto;
  right: auto;
  margin-top: 30px;
}

.qb-explain-modal-caption-text {
  font-size: 20px;
  text-shadow: none;
}

.qb-explain-modal-footer-padding {
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.qb-explain-modal-dont-show-text {
  cursor: pointer;
  margin-left: 5px;
}

.qb-explain-modal-buttons-margin {
  margin-left: 0px;
  margin-right: 0px;
}

.qb-explain-modal-button-left {
  width: 50%;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-radius: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.qb-explain-modal-button-right {
  width: 50%;
  border-radius: 0px 0px 4px 0px;
  border-top: 1px solid #005880;
  margin-left: 0px !important;
  float: right;
}