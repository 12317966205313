
.questions__wrap {


    .questions__node {
        margin: 12px 0;
        border: 1px solid $brand-primary;
        background: #eee;

        &.questions__node--collapsed {
            border: 1px solid #aaa;

            .questions__page {
                height: 0;
                margin: 0;
            }

            .questions__header {
                margin: 0;
            }

            .questions__footer {
                display: none;
            }

        }

        .questions__header {
            position: relative;
            transition: margin .2s;

            .questions__nav--indicator {
                display: block;
                width: 128px;
                padding: 4px;
                margin: auto;
                background: $brand-primary;
                border: none;
                cursor: pointer;
                font-size: 24px;
                text-align: center;
                color: white;

                &:disabled {
                    background: #608da7;
                }
            }
        }

        .questions__footer {
            position: relative;
            height: 44px;
            transition: margin .2s;
        }

        .questions__nav--button {
            position: absolute;
            padding: inherit;
            height: 28px;
            width: 128px;
            top: 8px;
            right: 0;
        }

        .questions__page {
            height: auto;
            margin: 12px 0;
            overflow: hidden;
            transition: height .2s, margin .2s;
        }
    }
}

@media (max-width: 512px) {
    .questions__wrap {

        .questions__node {

            .questions__header {

                .questions__nav--indicator {
                    margin: 0;
                }
            }
        }
    }
}