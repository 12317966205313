/*
 * Placeholder dashboard ideas
 */
.placeholders {
    margin-bottom: 30px;
    text-align: center;
}

.placeholders h4 {
    margin-bottom: 0;
}

.placeholder {
    margin-bottom: 20px;
}

.placeholder img {
    display: inline-block;
    border-radius: 50%;
}

.contacts-list, .message-block {
    display: inline-table;
    margin: 5px auto;
}

.contacts-list .contacts-list-info, .message-block .message-block-info {
    display: table-cell;
    vertical-align: middle;
}

.contacts-list .contacts-list-info .contacts-list-name, .message-block .message-block-info .message-block-subject {
    font-size: 16px;
    line-height: 18px;
}

.contacts-list .contacts-list-info .contacts-list-description, .message-block .message-block-info .message-block-content {
    font-size: 14px;
    line-height: 14px;
}

.conversation {
    height: 80%;

    .conversation-block {
        display: block;
        background-color: lightgrey;
        padding: 10px;
        margin: 10px;
        width: 80%;
        border: 1px solid slategray;
        border-radius: 10px;

        &.conversation-block-self {
            float: right;
        }
        &.conversation-block-other {
            float: left;
        }
    }
}

ul.message-list {
    list-style-type: none;
    padding-left: 0;
}

.img-responsive-list {
    display: block;
    margin-right: 10px;
    max-width: 100%;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid grey;
}