.reasons-teaser-styling {
  height: 100%;
  overflow: hidden;
}

.reasons-teaser-color-overlay-styling {
  height: 100%;
}

.reasons-no-margin-bottom {
  margin-bottom: 0px !important;
}