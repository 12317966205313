.list-lawsuit-title-padding {
  padding: 0px 0px 0px 0px;
}

.case-bg{
  background-color: #f0f0f0;
}

.list-lawsuit-box-padding {
  padding: 50px 0px 0px 0px;
}

.list-lawsuit-padding {
  padding-left: 0px;
  padding-right: 0px;
}

.list-lawsuit-claimamount-filter-padding {
  padding-right: 0;
  padding-left: 0;
}

.full-content-question {
  height: 340px;
  clear: both;
}

@media (max-width: $grid-float-breakpoint) {
  .list-lawsuit-claimamount-filter-padding {
    padding-left: 15px;
  }

  .list-lawsuit-filter-submit {
    position: relative;
    top: -80px;
    right: 0;
    margin-left: auto;
    margin-right: 0;
    max-width: 50%;
  }
}

@media (max-width: 400px) {
  .list-lawsuit-filter-submit {
    top: unset;
    max-width: 100%;
  }
}