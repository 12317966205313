/*
 * litigationCostCalculator - show
 */

.navbar-fixed-top, .navbar-fixed-bottom {
  z-index: 11000;
}

.col-sm-12.text-center.calculatorapp {
  min-height:900px;
}