/*
 * Navigation Bar
 */
.nav {
  font-size: 14px !important;
}

.navbar.navbar-inverse .navbar-nav > li > a:focus {
  color: rgb(99, 99, 99);
}

.navbar-right {
  li {
    .dropdown-menu {
      padding: 0;

      a {
        height: 60px;
      }
    }

    a {
      height: 59px;
      color: rgb(99, 99, 99);
    }

    a.current {
      color: white;
      background: #005880;
    }
  }
  .main-nav-item {
    color: rgb(99, 99, 99) !important;
  }
}

.navbar-toggle-hamburger{
  background-color: #0a0a0a;
  right: 0;
  position: absolute
}

.header-note {
  padding: 0 6px;
  background-color: #005880;
  color: white;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}

@media (max-width: 992px) {

  .header-note {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
  }

  .main-container {
    margin-top: 50px !important;
  }
}

#navbar-container {
  display: flex;
}

#navbar {
  flex-grow: 1;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #eee !important;
  color: #005880 !important;
}


#aequifin .navbar-fixed-top {
  background: #fff;
  border-color: #eeeeee;

  .brand-image {
    position: relative;
    top: 15px;
  }
  .nav a {
    font-size: 14px;
    display: block;
    line-height: 35px;
    padding-left: 30px;
    //padding-right: 30px;
    padding-right: 15px;
    font-weight: normal;
    position: relative;
    bottom: -1px;

    &:hover {
      color: rgb(0, 88, 128);
    }

    &.border-left {
      border-left: 0px solid white;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    ul.nav li.dropdown:hover > ul.dropdown-menu {
      display: block;
    }

    ul.dropdown-menu {
      border-radius: 0;
      width: auto;

      li a {
        display: block;
        height: 45px;

        &:hover {
          background: rgb(0, 88, 128);
          color: rgb(255, 255, 255);
          border: none;
          border-left: 0px solid rgb(203, 210, 218);
        }
      }
    }

    li.dropdown:last-child .dropdown-menu {
      right: 0;
      left: auto;
    }

    .navbar-nav {
      height: 100%;
    }

}

@media (max-width: $grid-float-breakpoint) {
  .navbar-inverse {
    .navbar-nav {
      a {
        line-height: 42px !important;
      }

      > .open {
        a, a:hover, a:focus {
          background-color: white !important;
        }
      }
    }
  }

  .border-left {
    border-left: none !important;
  }

  ul.dropdown-menu {
    > li {
      > a {
        padding-left: 50px !important;
      }
    }
  }

  .navbar-right {
    li {
      a {
        height: 42px;
      }
    }
  }
}

}

.navbar-header {
  flex-grow: 1;
}