@import '../variables';

select[multiple].select2 {
    height: $input-height-base;
}

form.fos_user_registration_register .form-group label.required:not(.radio-inline)::after,
form #app_contact .form-group label.required::after {
    content: " *";
}


/*
 * Form Fields
 */
.hide-input-file-element {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

input[type="checkbox"] {
    cursor: pointer;
}

input[type="radio"] {
    cursor: pointer;
}

[for=app_law_suit_wizard_step5_uploadFiles_uploadDocuments] {
    @extend .btn;
    @extend .btn-primary;
}

#aequifin .form-control {
    border-radius: 3px;
    border-color: #ccc;
}

#aequifin .select2-search__field {
    width: 100% !important;
}

#aequifin .select2-selection {
    border-radius: 3px;
    border: 1px solid #ccc;
}

#aequifin .select2-container--open .select2-selection {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

#aequifin .search .select2-selection {
    border-radius: 20px;
    border: 1px solid #ccc;
    height: 45px;
    line-height: 35px;
}

#aequifin .search .select2-container--open .select2-selection {
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    height: 45px;
    line-height: 35px;
}

textarea {
    resize: vertical;
    min-height: 300px;
}

.errmsg {
    color: red;
}

.firsterrmsg {
    color: red;
}

/* CountryDropdown */
.mozilla-dropdown-arrow {
    -moz-appearance: none;
}




