
$roboto-thin-font-path: "" !default;

/* roboto-thin-regular - latin */
@font-face {
  font-family: 'Roboto Thin';
  font-style: normal;
  font-weight: 100;
  src: local('Roboto Thin'), local('RobotoThin-Regular'),
  url('#{$roboto-thin-font-path}/Roboto-Thin.ttf') format('truetype'), /* Safari, Android, iOS */
}