// both sponsoring options

.case-cockpit-full-wrapper {
  margin: 4px;
  padding-left: 0px;
}

.case-cockpit-options-description {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
}

.case-cockpit-options-description-disabled {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  opacity: 0.1;
}

.case-cockpit-options-input {
  vertical-align: top;
}

.case-cockpit-options-input-disabled {
  vertical-align: top;
  pointer-events: none;
}

.case-cockpit-options-label {
  margin: 2px 12px;
  cursor: pointer;
}

.case-cockpit-options-label-disabled {
  margin: 2px 12px;
  pointer-events: none;
}

.case-cockpit-options-subtitle {
  margin-left: 24px;
  margin-bottom: 14px;
}

.case-cockpit-options-subtitle-disabled {
  margin-left: 24px;
  margin-bottom: 14px;
  opacity: 0.1;
}


.case-cockpit-first-option-container-notice {
  margin-left: 0px;
  margin-right: 0px;
  border:1px solid #015781;
  margin-bottom: 20px;
  border-radius: 6px;
  background-color: #015781 ;
  color: #ffffff ;
}
// first sponsoring option only

.case-cockpit-first-option-container {
  margin-left: 0px;
  margin-right: 0px;
  border:1px solid #015781;
  margin-bottom: 20px;
  border-radius: 6px;
}


.case-cockpit-sponsoring-container-title {
  background: #015781 ;
  color: #ffffff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.case-cockpit-first-option-button-container {
  padding-bottom: 15px;
  margin-bottom: 24px;
}

.case-cockpit-first-option-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 8px
}

.case-cockpit-first-option-buttons {
  margin: 4px;
}

.case-cockpit-first-option-amount-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.case-cockpit-first-option-amount-container {
  margin-top: 15px;
  margin-bottom: 20px;
}

// second sponsoring option only

.case-cockpit-second-option-container {
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  border: 3px solid #015781;
  padding-bottom: 20px;
  border-radius: 6px;
}

.case-cockpit-second-option-description-container {
  margin-bottom: 12px;
}

.case-cockpit-second-option-amount-container {
  width: 184px;
  margin: 16px;
}

.case-cockpit-current-rate-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 54px;
}

.case-cockpit-victim-rate-container {
  padding: 8px 8px 8px 16px;
}

.case-cockpit-current-rate-font-size {
  font-size: 24px;
}

.case-cockpit-current-rate-container {
  padding: 8px;
}

.case-cockpit-results-table-wrapper {
  margin-top: 24px;
}

.case-cockpit-results-table {
  width: 100%;
  border: 1px solid black;
  border-radius: 6px;
  overflow: hidden;
}

.case-cockpit-results-table-cell {
  border: none !important;
}

.case-cockpit-sponsor-protection-button-container{
  margin-top: 20px;
  margin-bottom: 14px;
}

.case-cockpit-sponsor-protection-info {
  display: inline-block;
  padding: 8px
}

.case-cockpit-make-sponsorship-button-container {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

.case-cockpit-more-info-wrapper {
  margin-top: 20px;
}

.case-cockpit-more-info-container {
  padding: 0 15px;
  margin-bottom: 15px;
}

.case-cockpit-more-info-field {
  margin-top: 19px;
}

.case-cockpit-more-info-variable-amount {
  padding-left: 22px;
}

.case-cockpit-more-info-disclaimer {
  margin-top: 10px;
  font-size: 11px;
}

.case-cockpit-small-label {
    font-size: 12px;
  }

.case-cockpit-no-left-padding{
  padding-left: 0;
}
.case-cockpit-no-right-padding{
  padding-right: 0;
}

@media only screen and (max-width: 1199px){
.label-min-height{
  min-height: 36px !important;
}
}

@media only screen and (max-width: 769px){
  .case-cockpit-no-left-padding {
    padding-left: 15px;
  }
  .case-cockpit-no-right-padding{
    padding-right: 15px;
  }
}

