
.timeline{
    mix-blend-mode: normal;
    backdrop-filter: blur(15px);
    position:initial;
    top:144px;
    box-shadow: 0 20px 53px -30px rgba(95, 102, 173, 0.566816);
    border-radius: 10px;

    .box{
        width:100%;
        //height:509px;
        background:#fbfcfd;
        margin-top:99.5px;
        .timeline-container{
            width:100%;
            height:auto;
            display:flex;

            .cards{
                margin-left:12px;
                transform:translateY(-50px);
                .card{
                    width: fit-content;
                    height:inherit;
                    padding-top:25px;
                    background: #FFFFFF;
                    border-radius: 10px;

                    box-shadow: 0 16px 15px -10px rgba(105, 96, 215, 0.0944602);
                    margin-bottom:10px;

                    &.mid{
                        height:71px;
                    }
                }
            }
        }
    }
}

.timeline-li{
    //padding-bottom: 1.5rem;
    border-left: 1px solid #abaaed;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
    &:last-child{
        border: 0;
        padding-bottom: 0;
    }
    &:before{
        content: '';
        width: 15px;
        height: 15px;
        background: white;
        border: 1px solid #4e5ed3;
        box-shadow: 3px 3px 0 #bab5f8;
        border-radius: 50%;
        position: absolute;
        left: -10px;
        top: 0;
    }
}
