/*
 * Margin
 */

.no-top-margin {
  margin-top: 0;
}

.no-bottom-margin {
  margin-bottom: 0px;
}
.no-right-margin {
  margin-right: 0px !important;
}

.margin-top {
  margin-top: 45px;
}

.title-padding-bottom {
  padding-bottom: 40px;
}

.title-margin-bottom {
  margin-bottom: 40px;
}

.margin-bottom {
  margin-bottom: 45px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-45 {
  margin-left: 45px;
}
.margin-l-r--25 {
  margin-left: -25px;
  margin-right: -25px;
}

.margin-bottom-end-page {
  margin-bottom: 120px;
}

.no-padding {
  padding: 0px;
}

.p {
  padding: 15px;
}

.p-l-r-5 {
  padding:5px;
}

.p-t {
  padding-top: 15px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-l {
  padding-left: 15px;
}

.p-r {
  padding-right: 15px;
}

.p-b {
  padding-bottom: 15px;
}

.p-b-10 {
  padding-bottom: 10px;
}

hr.m-hr {
  margin: 20px auto;
}

.p-10 {
  padding: 10px !important;
}

#notification {
  position:fixed;
  top:0px;
  width:100%;
  z-index:105;
  text-align:center;
  font-weight:normal;
  font-size:14px;
  font-weight:bold;
  color:white;
  background-color:#FF7800;
  padding:5px;
}
#notification span.dismiss {
  border:2px solid #FFF;
  padding:0 5px;
  cursor:pointer;
  float:right;
  margin-right:10px;
}
#notification a {
  color:white;
  text-decoration:none;
  font-weight:bold
}