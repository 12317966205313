/*
 * Form Wizard
 */

.wiz-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wiz-content {
  flex-grow: 1;
  flex-shrink: 1;
  width: calc(100% - 14px);
}

.wiz-action {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  & > .form-group {
    margin-left: 7px;
    &:last-child {
      margin-left: 0;
      margin-right: 7px;
    }
  }
}

.stepwizard {
  margin-top: 20px;
  display: table;
  width: 100%;
  min-height: 80px;
  position: relative;
}

.stepwizard-row:before {
  top: 12px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-index: 0;
}

.stepwizard-row {
  display: table-row;
  text-align: center;
}

.stepwizard-step {
  display: table-cell;
  max-width: 24px;
  text-align: center;
  position: relative;
}

.stepwizard-step a[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-step p {
  margin-top: 10px;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  hyphens: manual;

  &.current {
    font-weight: normal;
    font-size: 12px;
  }
}

.ck-editor__editable {
  min-height: 250px !important;
}
