/*
 * Progress Bar
 */
.progress-bar {
  width: 0;
  transition: none;
}

.progress-bar.support-progress-danger {
  background-color: rgb(213, 3, 29);
}

.progress-bar.support-progress-success {
  background-color: rgb(162, 217, 100);
}
.progress-bar.support-progress-total {
  background-color: #00c0ef;
}

