/*
 * Login Page
 */

.solution-item {
  display: flex;
  min-height: 585px;
  width: 100%;
  min-width: 230px;
  flex-direction: column;
  align-items: center;
  background: #f0f0f0;
  border: 2px solid white;
  border-radius: 6px;
}