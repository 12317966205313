.display-block {
  display: block;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.display-inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.neg-left-margin {
  margin-left: -15px;
}

.neg-right-margin {
  margin-right: -15px;
}

@media (max-width: $screen-sm) {
  .neg-left-margin.neg-left-margin--mobile-none {
    margin-left: 0;
  }

  .neg-right-margin.neg-right-margin--mobile-none {
    margin-right: 0;
  }
}