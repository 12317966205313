.flex-grid{
    display: flex;

    &.flex-grid--wrap {
        flex-wrap: wrap;
    }

    .col-1 {
        width: 100%;
    }

    .col-2 {
        width: 50%;
    }

    .col-3 {
        width: 33.33%;
    }

    .col-4 {
        width: 25%;
    }

    .col-6 {
        width: 16.66%;
    }

    .col-8 {
        width: 12.5%;
    }

    .space-top-bottom {
        margin: 8px 0;
    }

    .push-inside {
        padding: 24px;
    }

    .center-space {
        margin: 8px auto;
    }

    .push-right {
        margin: 0 0 0 auto;
    }

    .push-right-space {
        margin: 8px 0 8px auto;
    }
}

@media all and (max-width: $grid-float-breakpoint){
    .flex-grid {
        flex-direction: column;

        .col-1, .col-2, .col-3, .col-4, .col-6, .col-8 {
            width: 100%;
        }

        .center-space-mobile {
            display: grid;

            & * {
                margin: 8px auto;
            }
        }

        .push-right, .push-right-space {
            margin: 18px auto 0 auto;
        }
    }
}