/*
 * Color
 */
.colored-primary {
  color: rgb(0, 88, 128);
  //same as color: #015781;
  //same as color: #005880;
}

.background-colored-primary {
  background-color: rgb(0, 88, 128);
  //background: url("/images/home_bg.jpg") no-repeat center top; background-size: cover;
}

.colored-green {
  color: black;
  background-color: rgba(144, 238, 144, 0.2);
  text-align: center;
}

.colored-yellow {
  color: black;
  background-color: rgba(255, 215, 0, 0.2);
  text-align: center;
}

.colored-red {
  background-color: #dddddd;
  color: #636363;
  text-align: center;
}

.col {
  background-color: white;
}

.color-black {
  color: black;
}

.color-white {
  color: white;
}

.color-red {
  color: red;
}

.color-lightgrey {
  color: lightgrey;
}

.exclamation-sign-color {
  color: #a94442;
}

