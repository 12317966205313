.service-table-row-border-top{
  border-top: 1px solid rgb(0, 88, 128) !important;
}

.service-table-row-border-bottom{
  border-bottom: 2px solid #ddd;
}

.service-table-cell-border-top{
  border-top: 0px;
}

.service-case-deduction-margin-top {
  margin-top: 20px
}
