.swiper-space {
    display: block;
    width: calc(100% - 8px);
    height: 850px;
}
.swiper-container-wrapper {
    position: absolute;
    width: calc(100% - 8px);
    height: 850px;
    top: 64px;
    left: 2px;
    overflow: hidden;
}
.swiper-container {
    width: 100%;
    height: 850px;
    user-select: none;
}
.swiper-slide {
    z-index: 96;
    transform: scale(.4) !important;
    opacity: 0 !important;
    transition: transform, opacity .2s;

    &:hover .wrapper{
        margin-bottom: 20px;
        outline: rgb(1, 87, 129) solid 2px;
        box-shadow: rgba(0,0,0,0.3) 0 0 7px;
    }
}

.swiper-slide-prev, .swiper-slide-next {
    z-index: 98;
    transform: scale(.87) !important;
    opacity: 1 !important;
    transition: transform, opacity .2s;
}

.swiper-slide-next + .swiper-slide, .swiper-slide-prev-prev {
    z-index: 97;
    opacity: 1 !important;
    transform: scale(.7) !important;
    transition: transform, opacity .2s;

    &.minify {
        opacity: 0 !important;
    }
}

.swiper-slide-next + .swiper-slide {
    left: -72px;
}

.swiper-slide-prev-prev {
    left: 72px;
}

.swiper-slide-active {
    z-index: 99;
    opacity: 1 !important;
    transform: scale(1) !important;
    transition: transform .6s, opacity .2s;
}

.swiper-single {
    margin: auto;
    float: none !important;
}

.swiper-hide {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.8%;
    height: 850px;
    top: 0px;
    background: #fafcfb;
    color: $border-color;
    font-size: 24px;
    cursor: pointer;
    z-index: 999;

    &:hover {
        color: $abbr-border-color;
    }
}

.swiper-hide-left {
    left: 0px;
}

.swiper-hide-right {
    right: 0px;
}

.counter-wrap {
     display: inline-block;
     width: calc(100% - 24px);
     margin: 8px;

    &.top-space {
        width: 100%;
        margin: 128px 0 8px 0;
    }
 }

.counter-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.counter-item {
    display: flex;
    min-height: 358px;
    width: 100%;
    min-width: 230px;
    flex-direction: column;
    align-items: center;
    background: #f0f0f0;
    border: 2px solid white;
    border-radius: 6px;
}

.counter-title {
    display: block;
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.counter-num {
    display: block;
    color: #005880;
    margin-top: 64px;
    font-size: 92px;
}

.counter-num-no-spons {
    display: block;
    color: #005880;
    margin-top: 86px;
    font-size: 32px;
}

.counter-num-l {
    font-size: 128px;
}

.counter-pie {
    display: block;
    margin-top: 28px;
    max-width: 180px !important;
    max-height: 180px !important;
}

.counter-note {
    display: block;
    margin-top: 64px;
}

.counter-hint {
    display: block;
    margin: 16px 12px;
}

.chart-legend {
    display: flex;
    flex-direction: row;
    margin-top: 32px;

    .chart-legend-item {
        display: flex;
        flex-direction: row;
        margin: 3px;

        .chart-legend-color {
            display: block;
            height: 14px;
            width: 14px;
            margin-right: 6px;
        }

        .chart-legend-text {
            font-size: 12px;
        }
    }
}



@media only screen and (max-width: 860px) {
    .counter-container {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 512px) {

    .swiper-container {
        position: absolute;
        width: 450px;
        top: 0;
        left: calc(0px - ((400px - 100vw) / 2) - 42px);

        .single-ls {
            width: 95vw;
            max-width: 400px;
        }
    }

    .chart-legend {
        flex-direction: column;
    }

}

@media only screen and (max-width: 374px) {
    .swiper-container {
        padding: 40px 0 !important;
    }
    .swiper-slide-active {
        transform: scale(.92) !important;
        left: -2px !important;
    }
}