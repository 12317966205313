.wirecard-column-padding {
  padding: 20px;
}

.wirecard-wrapper {
  height: auto;
  border: 1px solid #ddd;
  margin-top: 30px;
  padding-bottom: 30px;
}

.wirecard-intro {
  height: 44px;
}

.wirecard-image-wrapper {
  height: 140px;
  margin-bottom: 20px;
  overflow: hidden;
}

.wirecard-image {
  cursor: pointer;
  height: auto;
  width: 100%;
}

.wirecard-list {
  margin-bottom: 30px;
  margin-left: -22px;
}