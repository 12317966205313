
.modal {
  overflow-y: auto;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

.logout-modal {
  width: 600px;
}

.modal-content {
  width:100%;
}

.modal-dialog-centered {
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  min-height:calc(100% - (.5rem * 2));
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height:calc(100% - (1.75rem * 2));
  }
}

.cookie-modal-padding-top {
  padding-top: 8px;
}

.cookie-modal-text-center {
  text-align: center;
}

.cookie-modal-disclaimer-styling {
  color: white;
  font-size: 16px;
}