/*
 * Icons
 */
.icon-holder {
  width: 100px;
  height: 100px;
  background: #fff;
  text-align: center;
  display: inline-block;
  i {
    font-size: 50px;
    color: rgb(0, 88, 128);
    position: relative;
    top: 25px;
  }
}

.icon-big {
  font-size: 60px;
}

.arrow-seperator {
  position: relative;
  top: 15px;
}

.icon-vs {
  background: rgb(0, 88, 128);
  padding: 20px 24px;
  display: inline-block;
  color: #fff;
  font-weight: normal;
  font-size: 18px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 3px;

  .icon-vs-content {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: inline-block;
  }
}

.glyphicon-ring {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid gray;
  color: gray;
  display: inline-table;
  text-align: center;
  padding: 0 2px 4px 0;
  margin: 0 10px 0 0;
}

.glyphicon-ring.glyphicon-ring-primary {
  border: 1px solid #005880;
  color: #005880;
}

.glyphicon-ring .glyphicon-bordered {
  font-size: 24px;
  vertical-align: middle;
  display: table-cell;
}