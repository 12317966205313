//in twigs: alreadyExecuted, checkEmail, and Success

.register-title-padding-top {
  padding-top: 50px;
}

//in register twig:

.registerform-title-margin-top {
  margin-top: 50px;
}

.registerform-feedback-margin-bottom {
  margin-bottom: 20px;
}

.registerform-notified-widget {
  float:left;
  margin: -10px 10px 5px 0;
}

.registerform-notified-email-margin-left {
  margin-left: 37px;
}

.registerform-terms-widget {
  float:left;
  margin: -10px 10px 0 0;
}

.registerform-cancellation-widget {
  float:left;
  margin: -10px 10px 24px 0;
}

.registerform-login-margin-top {
  margin-top: -16px;
}

/* The message box is shown when the user clicks on the password field */
#password-validation-message {
  display:none;
  background: #fefefe;
  color: #000;
  position: relative;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius:5px;
  box-shadow:0 1px 3px #ccc;
  border:1px solid #ddd;
}

#password-validation-message p {
  padding-left: 15px;
  font-size: 14px;
}

/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
}

.valid:before {
  position: relative;
  left: -15px;
  content: "✔";
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  left: -15px;
  content: "✖";
}