
/*
 * Tabs
 */
#aequifin .nav-tabs {
  border: none;
  margin: 0 auto;
  text-align: center;

  li {
    float: none;
    display: inline-block;
    text-align: center;
    border: none;

    a {
      margin: 0 20px;
      padding: 10px 20px 15px;
      font-size: 17px;
      color: rgb(0, 88, 128);
      border: none;

      &:hover {
        background: none;
      }
    }
    &.active {
      background: none;
      a {
        color: #000;
        border-bottom: 2px solid #000;
        background: none;
      }
    }
  }
}

#aequifin .tab-pane {
  margin: 40px 0;
}
