.change-subscription-padding-left {
  padding-left: 30px;
}

.edit-subscription-padding-left {
  padding-left: 0px;
}

.edit-subscription-explanation-styling {
  margin-left: 4px;
  cursor: pointer;
  font-weight: normal;
}