
/*
 * List
 */
dl {
  dt {
    font-weight: normal;
    font-size: 12px;
  }

  dd {
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 20px;
  }
}