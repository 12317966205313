/*
 * Video Wrapper
 */
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-wrapper{
  max-width:100%;
  width:auto;
  text-align:center;
}
.image-wrapper img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-wrap {
  position: relative;

  &:hover .video-button {
    background: $brand-info;
  }
}




.videoWrapperEmbedded {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;
}

.videoWrapperEmbedded iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-button {
  position: absolute;
  display: inline-block;
  height: 84px;
  width: 128px;
  padding: 24px 32px;
  top: calc(50% - 42px);
  left: calc(50% - 64px);
  border-radius: 12px;
  background: $brand-primary;
  text-align: center;
  cursor: pointer;
  transition: background-color .2s;

  &:hover {
    background: $brand-info;
  }

  svg {
    height: 24px;
    width: 24px;
    margin-top: 6px;
  }
}