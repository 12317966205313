@import "variables";

@font-face {
  font-family: 'simple-line-icons-aequifin-48';
  src:
    url('#{$icomoon-font-path}/simple-line-icons-aequifin-48.ttf?e9uxj0') format('truetype'),
    url('#{$icomoon-font-path}/simple-line-icons-aequifin-48.woff?e9uxj0') format('woff'),
    url('#{$icomoon-font-path}/simple-line-icons-aequifin-48.svg?e9uxj0#simple-line-icons-aequifin-48') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-aqf-48 {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'simple-line-icons-aequifin-48' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-48-people-male {
  &:before {
    content: $icon-48-people-male; 
  }
}
.icon-48-safe {
  &:before {
    content: $icon-48-safe; 
  }
}
.icon-48-pie-chart-2 {
  &:before {
    content: $icon-48-pie-chart-2; 
  }
}
.icon-48-check-circle-2 {
  &:before {
    content: $icon-48-check-circle-2; 
  }
}
.icon-48-bulb-idea {
  &:before {
    content: $icon-48-bulb-idea; 
  }
}
.icon-48-arrow-1-left {
  &:before {
    content: $icon-48-arrow-1-left; 
  }
}
.icon-48-arrow-1-right {
  &:before {
    content: $icon-48-arrow-1-right; 
  }
}
.icon-48-answer {
  &:before {
    content: $icon-48-answer; 
  }
}
.icon-48-email {
  &:before {
    content: $icon-48-email; 
  }
}
.icon-48-letter {
  &:before {
    content: $icon-48-letter; 
  }
}
.icon-48-message-2-alert {
  &:before {
    content: $icon-48-message-2-alert; 
  }
}
.icon-48-blackboard-3 {
  &:before {
    content: $icon-48-blackboard-3; 
  }
}
.icon-48-nerd-glasses {
  &:before {
    content: $icon-48-nerd-glasses; 
  }
}
.icon-48-university {
  &:before {
    content: $icon-48-university; 
  }
}
.icon-48-write-2 {
  &:before {
    content: $icon-48-write-2; 
  }
}
.icon-48-check-circle {
  &:before {
    content: $icon-48-check-circle; 
  }
}
.icon-48-lock {
  &:before {
    content: $icon-48-lock; 
  }
}
.icon-48-magnifier-1 {
  &:before {
    content: $icon-48-magnifier-1; 
  }
}
.icon-48-file-copy {
  &:before {
    content: $icon-48-file-copy; 
  }
}
.icon-48-folder-open {
  &:before {
    content: $icon-48-folder-open; 
  }
}
.icon-48-7-support-2 {
  &:before {
    content: $icon-48-7-support-2; 
  }
}
.icon-48-balance {
  &:before {
    content: $icon-48-balance; 
  }
}
.icon-48-banknotes-1 {
  &:before {
    content: $icon-48-banknotes-1; 
  }
}
.icon-48-money-bag-coins {
  &:before {
    content: $icon-48-money-bag-coins; 
  }
}
.icon-48-money-bag {
  &:before {
    content: $icon-48-money-bag; 
  }
}
.icon-48-search-money {
  &:before {
    content: $icon-48-search-money; 
  }
}
.icon-48-search-stats {
  &:before {
    content: $icon-48-search-stats; 
  }
}
.icon-48-support {
  &:before {
    content: $icon-48-support; 
  }
}
.icon-48-auction {
  &:before {
    content: $icon-48-auction; 
  }
}
.icon-48-puzzle {
  &:before {
    content: $icon-48-puzzle; 
  }
}
.icon-48-location-3 {
  &:before {
    content: $icon-48-location-3; 
  }
}
.icon-48-timer-2 {
  &:before {
    content: $icon-48-timer-2; 
  }
}
.icon-48-love {
  &:before {
    content: $icon-48-love; 
  }
}
.icon-48-people {
  &:before {
    content: $icon-48-people; 
  }
}
.icon-48-team-hierarchy {
  &:before {
    content: $icon-48-team-hierarchy; 
  }
}
.icon-48-like-2 {
  &:before {
    content: $icon-48-like-2; 
  }
}
.icon-48-trophy-2 {
  &:before {
    content: $icon-48-trophy-2; 
  }
}

