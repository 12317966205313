.profile-edit-padding-left {
  padding-left: 15px;
}

.profile-sponsoring-summary-ident-request-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-bottom: 15px;
}

.profile-sponsoring-summary-security-request-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-bottom: 32px;
}

.profile-sponsoring-summary-info-margin {
  margin-left: 4px;
}