.de-button {
  color: #636363 !important;
  display: inline-block !important;
  padding-right: 10px !important;
}

.en-button {
  color: #636363 !important;
  display: inline-block !important;
  padding-left: 10px !important;
}

.language-separation-line {
  width: 1px;
  height: 30px;
  display: inline-block;
  margin: 0 0 -10px 0;
  padding: 0 0 0 0;
  background-color: #636363;
}

.flag-icon-margin {
  margin-right: 5px;
}

.header-timer-icon {
  font-size: 15px;
  margin-right: 5px;
}

.header-time {
  font-size: 15px;
}

.five-minute-modal-carousel-inner {
  padding: 30px 50px 30px 50px;
}

.five-minute-modal-carousel-caption {
  position: relative;
  left: auto;
  right: auto;
  margin-top: 30px;
}

.five-minute-modal-carousel-text {
  font-size: 20px;
  color: #015781;
  text-shadow: none;
}

.five-minute-modal-button-div {
  margin-left: 0px;
  margin-right: 0px;
}

.five-minute-modal-logout-button {
  width: 50%;
  border-right: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-radius: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

.five-minute-modal-restart-button {
  width: 50%;
  border-radius: 0px 0px 4px 0px;
  border-top: 1px solid #005880;
  margin-left: 0px !important;
  float: right;
}

.time-expired-modal-carousel-caption {
  position: relative;
  left: auto;
  right: auto;
  margin-top: 62px
}

.time-expired-modal-timer-icon {
  color: #015781;
  font-size: 32px;
  margin-right: 5px;
  text-shadow: none;
}

.time-expired-modal-text {
  font-size: 20px;
  color: #015781;
  text-shadow: none;
}

.time-expired-modal-restart-timer {
  width: 100%;
  border-radius: 0px 0px 4px 4px;
  border-top: 1px solid #005880;
}

//.container-fluid {
//  display: flex;
//}

.navbar-header {
  display: flex;
  flex: 1;
  min-height: 50px;
}

@media only screen and (min-width: 1024px){
  .navbar-slogan {
    display: none;
  }
}

.navbar-slogan {
  align-self: flex-start;

  margin-left: 1em;
  margin-top: 4px;
}

#slogan-wimpel {
  display: none;
}

@media only screen and (min-width: 1024px) {
  #slogan-wimpel {
    position: fixed;
    display: inherit;
    > .wimpel-text {
      font-family: 'Roboto', sans-serif;
      font-size: 10px;
      font-weight: 100;
    }
  }
}

#slogan-wimpel-2 {
  position: fixed;
  display: inherit;
  > .wimpel-text {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 100;
  }
}

@media only screen and (min-width: 1025px) {
  #slogan-wimpel-2 {
    display: none;
  }
}
