

/*
 * THIS WHOLE FILE SHOULD STILL BE RE-ORGANIZED INTO THE OTHER SCSS FILES
 */


html, body {
    font-family: 'Roboto Condensed', sans-serif;
    color: rgb(99, 99, 99);
    -webkit-text-size-adjust: 100%;
    background: #fafcfb;
}

.nav-justified > li {
    display: table-cell;
    width: 1%;
}

/*
 * Main Content
 */
.main {
    padding: 20px;
}

@media (min-width: $grid-float-breakpoint) {
    .main {
        padding-right: 40px;
        padding-left: 40px;
    }
}

.main .page-header {
    margin-top: 0;
}

.teaser {
    min-height: 420px;
    color: #fff;

    > div {
        min-height: 420px;
    }
}

.content-box {
    padding: 70px 0;
}

.party-detail, .document-detail {
    .caption {
        font-weight: 300;
        font-size: 25px;
        margin-bottom: 66px;

        .report-meta-caption {
            margin-top: 20px;
        }

        .report-text {
            font-size: 14px;
        }
    }
    .caption.party-two {
        font-weight: 300;
        font-size: 25px;
        margin-top: 14px;
    }
    .subcaption {
        color: rgb(209, 209, 209);
        margin-bottom: 10px;
        font-size: 11px;
    }
}

.reason-item-holder {
    margin-bottom: 40px;

    .media-left {
        padding-right: 35px;
        i {
            color: #fff;
            font-size: 50px;
            position: relative;
            top: 25px;
        }
    }

    .col-sm-12 {
        margin-bottom: 45px;
    }
}

.press-item-holder {
    margin-top: 60px;
    margin-bottom: 40px;
    padding: 0 20px;

    .press-item {
        margin-bottom: 2px;
        padding: 0 1px;

        .press-item-img {
            border-radius: 3px;
            width: 100%;
        }
    }
}

.content-box-register-claim {
    padding: 40px;
}

.register-content-box {
    background: rgb(246, 247, 248);
    padding: 20px;
    overflow: hidden;

    .secondary-bg {
        background: none;
    }
    .control-label {
        color: #636363;
    }

    hr {
        border-width: 3px 0 0;
    }
}

.become-lawyer-content-box {
    margin-bottom: 40px;
    .adult, h3 {
        color: rgb(0, 88, 128);
        font-weight: normal;
        border-bottom: 3px solid rgb(215, 220, 226);
        display: inline-block;
        padding-bottom: 5px;
        width: 100%;
        margin-bottom: 20px;
    }

    .report {
        h3 {
            color: initial;
            font-weight: initial;
            border-bottom: initial;
            display: initial;
            padding-bottom: initial;
            width: initial;
            margin-bottom: initial;
        }
    }

    .media-left {
        padding-right: 30px;
        i {
            color: rgb(0, 88, 128);
            font-size: 50px;
            position: relative;
            top: 25px;
        }
    }
}

.content-box-reasons {
    .media-left {
        padding-right: 30px;
        i {
            color: #fff;
            font-size: 50px;
            position: relative;
            top: 25px;
        }
    }
}

.meta-caption {
    display: block;
    color: rgb(209, 209, 209);
    font-size: 11px;
}

.meta-content {
    display: block;
    font-weight: normal;
    font-size: 18px;
}

.caseProposalHeader {
    padding-bottom: 40px;
}

.caseProposalBorder {
    box-shadow: 0 0 0 2pt #015781;
    padding: 40px;
    border-radius: 20px;
}

.editaccess-disclaimer-border-top {
    border-top: 1px solid black;
}

// Todo: Replace colors
a.message {
    display: block;
    color: #005880;

    &:hover {
        background-color: #f5f5f5;
        color: #23527c;
    }

    &.message-read {
        color: grey;
        font-weight: normal;

        &:hover {
            color: darkslategrey;
        }

        .glyphicon-ring.glyphicon-ring-primary {
            border: 1px solid grey;
            color: grey;
        }
    }
}

a[disabled] {
    pointer-events: none;
}

/*
 * Flex
 */
@media (min-width: $screen-sm) {
    .flex-sm {
        display: flex;
        flex-direction: row;

        .flex-content-sm {
            height: 100%;
        }
    }
}

    /* .fixed-row is used instead of .container (never nest containers) */
.fixed-row {
    @extend .row;

    @media (min-width: $screen-sm) {
        width: 730px;
    }
    @media (min-width: $screen-md) {
        width: 950px;
    }
    @media (min-width: $screen-lg) {
        width: $container-large-desktop;
    }

    margin-left: auto;
    margin-right: auto;
}

.group {
    border: none;
    transition: all 0.5s;
    cursor: pointer;
}

.group span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.group span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.group1 {
    border: none;
    transition: all 0.5s;
    cursor: pointer;
}

.group1 span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.group1 span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.howToBoxes {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
}

.howToBoxesContainer {
    padding-left: 0px;
    padding-right: 0px;
}

.shortHowToTitle {
    padding-left: 40px;
}

.equal {
    display: -webkit-flex;
    display: flex;
}

.label-inline {
    display: inline;
}