
/*
 * Advertisement container
 */
.advertisement-wrap {
  display: inline-block;
  width: calc(100% - 24px);
  margin: 8px;
}

.advertisement-container {
  display: flex;
  flex-direction: row;
  //justify-content: center;
  //justify-content: space-between;
  justify-content: space-around;
  width: 100%;

  .one-advertisements {
    width: 476px;
  }

  .two-advertisements {
    width: 242px;
  }

  .three-advertisements {
    width: 164px;
  }
}

@media (max-width: 500px) {
  .one-advertisements {
    width: 242px !important;
  }
}

.advertisement-item {
  height: 68px;

  display: flex;

  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  padding: 2px;

  margin: 8px;
  background: #f0f0f0;
  border: 2px solid white;
  border-radius: 6px;
}

@media only screen and (max-width: 860px) {
  .advertisement-container {
    flex-direction: column;
    align-items: center;
  }
}