
$social-share-font-path: "fonts" !default;

@font-face{font-family:'social-share-kit';
  src:url('#{$social-share-font-path}/social-share-kit.eot');
  src:url('#{$social-share-font-path}/social-share-kit.eot?#iefix') format('embedded-opentype'),
  url('#{$social-share-font-path}/social-share-kit.woff') format('woff'),
  url('#{$social-share-font-path}/social-share-kit.ttf') format('truetype'),
  url('#{$social-share-font-path}/social-share-kit.svg#social-share-kit') format('svg');
  font-weight:normal;
  font-style:normal;
}