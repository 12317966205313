// custom button styles

.btn-cancel {
    @extend .btn-danger
}

.btn-save,
.btn-submit {
    @extend .btn-success
}

.btn-next {
    @extend .btn-primary
}

.btn-outline {
    background-color: transparent;
    color: inherit;
    transition: all .5s;
}

.btn-primary.btn-outline {
    color: $brand-primary;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}

.login button.btn {
    font-weight: bold;
    padding-left: 25px;
    padding-right: 25px;
}


/*
 * Buttons
 */
.btn {
    font-weight: normal;
    padding: 15px 40px 13px;
    border: none;
    border-radius: 20px;
    font-size: 13px;
    white-space: normal;

    &:focus {
        outline: none !important;
    }
}

.btn-primary {
    background: rgb(0, 88, 128);

    &:hover {
        background: #005880;
    }
}

.btn-white {
    background: #fff;
    color: #636363;

    &:hover {
        background: #fbfcfb;
        color: #636363;
    }
}

.btn-whiteblue {
    background: #fff;
    color: rgb(0, 88, 128);

    &:hover {
        background: #fbfcfb;
        color: rgb(0, 88, 128);
    }
}

.btn-register {
    border-radius: 0;
    border: 3px solid #fff;
    font-size: 20px;

    &:hover {
        border: 3px solid #fff;
    }
}

.openSponsoringTabButton {
    border: 2px solid white;
}

.btn-center {
    padding: 14px 4px;
}

.btn-select-all {
    font-size: 12px;
    padding: 2px 12px;
    font-weight: normal;
    margin-top: -2px;
}

.btn-transparent {
    background: none;
    border: 2px solid #005880;
}

.btn-active {
    color: #fff;
    background-color: #006fa1;

    &:hover {
        color: #fff;
    }

    &:active {
        color: #fff;
    }
}

.btn-disabled {
    color: #fff;
    background-color: #c9cac9;
}

.btn-circle {
    width: 24px;
    height: 24px;
    text-align: center;
    padding: 4px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 12px;
}

.btn-white {
    color: #fff;
    border-color: #fff;
}

.btn-h-max-40 {
    max-height: 40px;
}

/*
 * Clickable Divs
 */

.disabledDiv {
    pointer-events: none;
}

.enabledDiv {
    pointer-events: auto;
}

.clickableDiv {
    cursor: pointer;
}


.button-like {
    border: 2px solid white;
    background-color: transparent;
    text-decoration: none;
    padding: 1rem;
    position: relative;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    border-radius: 3rem;
    color: white;
    transition: all ease 0.4s;

    span {
        margin-left: 0.5rem;
    }
    .fa,
    span {
        transition: all ease 0.4s;
    }

    &:focus {
        background-color: transparent;

        .fa,
        span {
            color: white;
        }
    }

    &:hover {
        border-color: #a2d964;
        background-color: transparent;

        .fa,
        span {
            color: #a2d964;
        }
    }
}

.liked {
    background-color: #a2d964;
    border-color: #a2d964;

    .fa,
    span {
        color: white;

    }

    &:focus {
        background-color: #a2d964;

        .fa,
        span {
            color: white;
        }
    }

    &:hover {
        background-color: #a2d964;
        border-color: #a2d964;

        .fa,
        span {
            color: white;
        }
    }
}

