

/*
 * THIS WHOLE FILE SHOULD STILL BE RE-ORGANIZED INTO THE OTHER SCSS FILES
 */


@import 'variables';

.secondary-bg {
    background-color: $bg-secondary;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: #fff;
    background-color: $brand-primary;
}

.rounded {
    border-radius: $border-radius-base;
}

.max-width {
    width: 100%;
}

.no-radius {
    border-radius: 0;
}

.padding-top-20 {
    padding-top: 20px;
}

#top .av_inherit_color .form-errors {
    color: #d08686;
}

.login input,
#top .login input[type="text"],
#top .login input[type="password"] {
    padding-left: 15px;
    font-size: 15px;
}

/** avia override  */
#top .av_inherit_color button.btn-default {
    color: $btn-default-color;
}

#top input[type="text"].rounded,
#top input[type="password"].rounded {
    border-radius: $border-radius-base;
}

#top .av_inherit_color a.no-decoration {
    text-decoration: none;
}

a.no-decoration:hover, a.no-decoration:focus, a.no-decoration:visited {
    text-decoration: none;
}

ul.list-with-bullets {
    list-style-type: disc;
}

.shadow-panel {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16),
    0 2px 10px 0 rgba(0, 0, 0, .12);

    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16),
    0 2px 10px 0 rgba(0, 0, 0, .12);
}

.radius-zero {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.searchFields .select2-selection--single {
    border-radius: 50px;
}

#aequifin .form-control.invalid {
    border-color: #E50005;
}

.password-info {
    color: $brand-primary;
    font-size: 18px;
}

.list-attorney a.btn-primary {
    padding: 15px 15px 13px
}

table.table.list-sponsoring {

    td.active {
        background-color: #5cb85c;
    }

    td.closed {
        background-color: #d8d8d8;
    }

    td.unsuccessful {
        background-color: #d8d8d8;
    }
}

.accept-legal-notice {

    .legal-notice-content {
        height: 300px;
        overflow-y: scroll;
        margin-bottom: 20px;
    }

    button.dismiss-terms {
        white-space: normal;
    }
}


.help-popup-video {

    .help-popup-content {
        height: 350px;
        overflow-y: scroll;
        margin-bottom: 20px;
    }
}

.qa-toggle {
    float: none;
    margin: 24px auto;

    & div {
        margin: 18px;
    }
}

.auto-scroll-x {
    overflow-x: auto;
}

.full-width {
    width: 100%;
}

.no-bold-children * {
    font-weight: normal;
}

@media (max-width: 512px) {
    .ck-dropdown {
        display: block !important;
        width: 100% !important;
    }

    .ck-dropdown__button {
        width: 100% !important;
    }
}

.share-include{
    position: absolute;
    z-index: 0;
    right: -33px;
    width: 54px;
    padding-right: 0px;
}
.share-div{
    position: relative;
}

@media (max-width: 1060px){
    .share-div{
        position:fixed;
    }
    .share-include{
        z-index: 2000;
    }
}

@media (min-width: 1060px){
    .share-bottoms:hover{
        padding-right: 0px;
        margin-right: -8px;
        width: 54px;
    }

}

@media (max-width: $screen-sm) {
    .tab-ctm {
        & li {
            display: block;
            width: auto;
        }
    }
}

