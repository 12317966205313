.teaser-cc-lawsuit-state--wrapper {
  position: relative;
}

.teaser-cc-lawsuit-state {
  position: absolute;
  //margin-left: -20px;
  left: 0;
}

.teaser-cc-lawsuit-state-no-target {
  position: absolute;
  //margin-left: -20px;
  bottom: 0;
  left: 0;
}

.teaser-cc-right-side-wrapper {
  padding-bottom: 0;
}

.teaser-cc-law-field {
  display: inline-block;
  height: 36px;
  margin-top: 12px;
  margin-right: 4px;
  float: left;
}

.teaser-cc-edit-buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 12px 0;
  float: right;
}

.teaser-cc-refresh-button {
  border: 2px solid white !important;
  font-size: 18px;
  padding: 5px 12px 6px !important;
}

.teaser-cc-subscribe-button {
  border: 2px solid white;
}

.teaser-cc-like-label {
  margin: 12px 12px;
  float: right;
}

.teaser-cc-lawsuit-caption {
  font-size: 34px;
  hyphens: auto;
}

.teaser-cc-lawsuit-description-container {
  height: 136px;
}

.teaser-cc-support-needed-text {
  line-height: 2px;
}

.teaser-cc-support-needed-container {
  line-height: 12px;
}

.teaser-cc-support-needed-font-size {
  font-size: 22px;
}

.teaser-cc-progress-bar-margin {
  margin-bottom: 10px;
}

.teaser-cc-left-brick-container {
  padding: 0;
}

.teaser-cc-timer-brick {
  border-top: 1px solid white;
  border-right: 1px solid white;
  height: 90px;
  color: white;
}

.teaser-cc-timer-brick-text {
  color: white;
  margin-top: 14px;
  margin-bottom: 0;
  max-height: 13px;
}

.teaser-cc-timer {
  color: white;
  font-size: 14px;
  line-height: 14px;
  margin-top: 8px;
}

.teaser-cc-rate-brick {
  border-top: 1px solid white;
  border-right: 1px solid white;
  height: 90px;
  padding-right: 0px;
  padding-left: 0px;
}

.teaser-cc-rate-brick-text {
  color: white;
  margin-top: 14px;
  margin-bottom: 0;
  max-height: 13px;
  hyphens: auto;
}

.teaser-cc-rate-with-target-text {
  margin-top: 13px;
}

.teaser-cc-rate-not-found-text {
  font-size: 14px;
  line-height: 14px;
}

.teaser-cc-rate-no-target-text {
  margin-top: 14px;
  font-size: 24px !important;
}

.teaser-cc-support-brick {
  border-top: 1px solid white;
  border-right: 1px solid white;
  height: 90px;
}

.teaser-cc-support-brick-text {
  color: white;
  margin-top: 14px;
  margin-bottom: 0;
  //max-height: 13px;
  hyphens: auto
}

.teaser-cc-support {
  margin-top: 13px;
  font-size: 24px !important;
}

.teaser-cc-right-side-bricks-container {
  padding: 0;
}

.teaser-cc-target-amount-brick {
  border-top: 1px solid white;
  border-right: 1px solid white;
  height: 90px;
  color: white;
}

.teaser-cc-target-amount-brick-text {
  margin-top: 14px;
  margin-bottom: 0;
  //max-height: 13px;
}

.teaser-cc-claim-amount-brick {
  border-top: 1px solid white;
  border-right: 1px solid white;
  height: 90px;
}

.teaser-cc-claim-amount-brick-text {
  margin-top: 14px;
  margin-bottom: 0;
  //max-height: 13px;
}

.teaser-cc-support-min-amount-brick {
  border-top: 1px solid white;
  height: 90px;
}

.teaser-cc-support-min-amount-brick-text {
  margin-top: 14px;
  margin-bottom: 0;
  max-height: 13px;
}

.teaser-cc-right-brick-values {
  margin-top: 13px;
  font-size: 24px !important;
}

.teaser-cc-step-title {
  margin-top: 14px;
  font-size: 20px !important;
}


.teaser-cc-step-info {
  margin: 0px -25px 20px -25px;
  border-bottom: 1px solid white;
}
.border-bottom{
  border-bottom: 1px solid white;
}

.teaser-cc-lc-lawsuit-info {
  margin: 0px -25px -10px -25px;
}


.popup-video-body {margin:2rem;}

.popup-video-modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

.popup-video-modal-body {
  position:relative;
  padding:0px;
}
.popup-video-close {
  position:absolute;
  right:-30px;
  top:0;
  z-index:999;
  font-size:2rem;
  font-weight: normal;
  color:#fff;
  opacity:1;
}


.video-thumbnail {
  position: relative;
  cursor: pointer;

  &:before {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    content: "\f01d";
    font-family: FontAwesome;
    font-size: 100px;
    color: #fff;
    opacity: .8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
  &:hover:before {
    color: #eee;
  }
}