.news-wrapper-styling {
  word-wrap: break-word;
  margin-bottom: 20px;
}

.news-teaser-border {
  border-bottom: 1px solid #CBD3DD;
}

.news-teaser-date {
  color: #636363;
  margin-bottom: 10px;
  font-size: 12px;
}

.news-teaser-sub-head {
  color: #636363;
  margin-bottom: 10px;
  font-size: 16px;
}

.news-press-requests-wrapper-styling {
  overflow-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;
}

.news-press-requests-inner-styling {
  padding: 20px 20px 20px 20px;
  min-height: auto;
}

.news-show-image-margin {
  margin-bottom: 20px;
  margin-left: 20px;
}

.news-show-image-dimensions {
  max-height: 225px;
  max-width: 350px;
}