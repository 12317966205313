@import "variables";

@font-face {
  font-family: 'simple-line-icons-aequifin-24';
  src:
    url('#{$icomoon-font-path}/simple-line-icons-aequifin-24.ttf?mn2y1l') format('truetype'),
    url('#{$icomoon-font-path}/simple-line-icons-aequifin-24.woff?mn2y1l') format('woff'),
    url('#{$icomoon-font-path}/simple-line-icons-aequifin-24.svg?mn2y1l#simple-line-icons-aequifin-24') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon-aqf {
  /* use !important to prevent issues with browser extensions that change fonts */
  height: 14px;
  width: 14px;
  font-family: 'simple-line-icons-aequifin-24' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-stay-right {
  right: 12px;
  top: 0;
  position: absolute;
}


.icon-bulb-idea {
  &:before {
    content: $icon-24-bulb-idea; 
  }
}
.icon-check-circle-2 {
  &:before {
    content: $icon-24-check-circle-2; 
  }
}
.icon-people-male {
  &:before {
    content: $icon-24-people-male; 
  }
}
.icon-safe {
  &:before {
    content: $icon-24-safe; 
  }
}
.icon-pie-chart-2 {
  &:before {
    content: $icon-24-pie-chart-2; 
  }
}
.icon-info {
  &:before {
    content: $icon-24-info; 
  }
}
.icon-arrow-1-left {
  &:before {
    content: $icon-24-arrow-1-left; 
  }
}
.icon-arrow-1-right {
  &:before {
    content: $icon-24-arrow-1-right; 
  }
}
.icon-answer {
  &:before {
    content: $icon-24-answer; 
  }
}
.icon-email {
  &:before {
    content: $icon-24-email; 
  }
}
.icon-letter {
  &:before {
    content: $icon-24-letter; 
  }
}
.icon-message-2-alert {
  &:before {
    content: $icon-24-message-2-alert; 
  }
}
.icon-auction {
  &:before {
    content: $icon-24-auction; 
  }
}
.icon-blackboard-3 {
  &:before {
    content: $icon-24-blackboard-3; 
  }
}
.icon-nerd-glasses {
  &:before {
    content: $icon-24-nerd-glasses; 
  }
}
.icon-university {
  &:before {
    content: $icon-24-university; 
  }
}
.icon-7-support-2 {
  &:before {
    content: $icon-24-7-support-2; 
  }
}
.icon-balance {
  &:before {
    content: $icon-24-balance; 
  }
}
.icon-banknotes-1 {
  &:before {
    content: $icon-24-banknotes-1; 
  }
}
.icon-money-bag-coins {
  &:before {
    content: $icon-24-money-bag-coins; 
  }
}
.icon-money-bag {
  &:before {
    content: $icon-24-money-bag; 
  }
}
.icon-search-money {
  &:before {
    content: $icon-24-search-money; 
  }
}
.icon-search-stats-1 {
  &:before {
    content: $icon-24-search-stats-1; 
  }
}
.icon-support {
  &:before {
    content: $icon-24-support; 
  }
}
.icon-write-2 {
  &:before {
    content: $icon-24-write-2; 
  }
}
.icon-check-circle {
  &:before {
    content: $icon-24-check-circle; 
  }
}
.icon-lock {
  &:before {
    content: $icon-24-lock; 
  }
}
.icon-magnifier-1 {
  &:before {
    content: $icon-24-magnifier-1; 
  }
}
.icon-file-copy {
  &:before {
    content: $icon-24-file-copy; 
  }
}
.icon-folder-open {
  &:before {
    content: $icon-24-folder-open; 
  }
}
.icon-puzzle {
  &:before {
    content: $icon-24-puzzle; 
  }
}
.icon-location-3 {
  &:before {
    content: $icon-24-location-3; 
  }
}
.icon-timer-2 {
  &:before {
    content: $icon-24-timer-2; 
  }
}
.icon-love {
  &:before {
    content: $icon-24-love; 
  }
}
.icon-people {
  &:before {
    content: $icon-24-people; 
  }
}
.icon-team-hierarchy {
  &:before {
    content: $icon-24-team-hierarchy; 
  }
}
.icon-like-2 {
  &:before {
    content: $icon-24-like-2; 
  }
}
.icon-trophy-2 {
  &:before {
    content: $icon-24-trophy-2; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-24-linkedin; 
  }
}
.icon-linkedin2 {
  &:before {
    content: $icon-24-linkedin2; 
  }
}
.icon-xing {
  &:before {
    content: $icon-24-xing; 
  }
}
.icon-xing2 {
  &:before {
    content: $icon-24-xing2; 
  }
}

